import React, {useEffect, useState, useRef} from 'react';
import styles from './translationLanguage.module.scss';
import {makeRequest} from '../utils/APIsUtils/httpsUtils';
import {connect} from 'react-redux';
import {setNewTranslationList} from '../../redux/actions/uiActions';
import {setNotificationData} from '../../redux/actions/uiActions';
import TranslationDeleteModal from './TranslationDeleteModal';
import Notification from '../shared/notification';
import Background from '../shared/Modal/Background/Background';
import ModalWrapper from '../shared/Modal/ModalWrapper/ModalWrapper';
import TranslationDetailsDrawerComponent from './TranslationDetailsDrawer';
import {
  drawerCalculatedHeight,
  drawerOverlayCalculatedHeight,
  mobDrawerToOrignalState,
  pullbox,
} from '../utils/commonUtil';
import LoadingShimmerList from '../shared/LoadingShimmerList';
import LoadingShimmer from '../shared/LoadingShimmer';
import TranslationConfirmModal from './TranslationConfirmModal';
import useLocalMarkets from '../hooks/useLocalMarkets';
import {marketEnvConfig} from '../../marketEnvConfig';

const TranslationLanguage = ({
  isLp=false,
  learningPathId,
  translationFor,
  placeholders,
  // data,

  // Course File
  // adaptFileButtonState,
  // uploadingFileButton,
  // uploadingFileButtonHindi,
  // fileDataTemp,
  // fileDataTempHindi,
  // isCourseIDFetched,
  previewAdaptFileCreateNewCourse,
  courseData,
  newCourseID,
  // Redux State
  addedTranslationListState,
  setNewTranslationList,
  setNotificationData,
  isEditCourse = false,
  isPrimaryLPAdded = false,
  setPrimaryLPAdded,
  isPrimaryCourseAdded = false,
  showPrimaryNotification,
  setPrimaryCourseAdded = () => {},
  setShowPrimaryNotification = () => { },
  setMultilangugalState = () => {}
}) => {
  const [isDrawer, setDrawer] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isAdd, setAdd] = useState(false);
  const [isDeleteRecord, setDeleteRecord] = useState(false);
  const [selectLanguage, setSelectedLanguage] = useState({});
  const [language, setLanguage] = useState([]);
  const [translationList, setTranslationList] = useState([]);
  const [isAllLangDisabled, setIsAllLangDisabled] = useState();
  // let translationLanguage = JSON.parse(localStorage.getItem('languageName'));

  // Course File
  const [previewAdaptFileFlag, setPreviewAdaptFileFlag] = useState(false);
  const [uploadingFileButton, setUploadingFileButton] = useState(false);
  const [uploadingFileButtonHindi, setUploadingFileButtonHindi] = useState(false);
  const [adaptFileButtonState, setAdaptFileButtonState] = useState(true);
  const [adaptFileButtonStateHindi, setAdaptFileButtonStateHindi] = useState(true);
  const [disableImageLib, setDisableImageLib] = useState(false);
  const [adaptFileState, setAdaptFileState] = useState('INPROGRESS');
  const [openAdaptFileModal, setOpenAdaptFileModal] = useState(false);
  const [editState, setEditState] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [data, setData] = useState(null);
  const [fileData, setFileData] = useState(null);
  // const [newCourseID, setNewCourseID] = useState(null);
  const [fileDataTemp, setFileDataTemp] = useState(null);
  const [fileDataHindi, setFileDataHindi] = useState(null);
  const [fileDataTempHindi, setFileDataTempHindi] = useState(null);
  const [isCourseIDFetched, setIsCourseIDFetched] = useState(false);
  const [publishButtonAfterPreview, setPublishButtonAfterPreview] = useState(true);
  const [openPreviewAdaptFile, setOpenPreviewAdaptFile] = useState(false);
  const [isAPICallInProgress, setIsAPICallInProgress] = useState(false);
  const [notificationBanerCount, setNotificationBanerCount] = useState(0);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  // const [apiData, setApiData] = useState(null);
  // const [isOnline, setOnline] = useState(true);
  const [version, setVersion] = useState(0);
  const [adaptFile, setAdaptFile] = useState('');
  const [isLocale, setIsLocale] = useState(false);
  const [replaceFile, setReplaceFile] = useState(false);
  const marketConfig = marketEnvConfig();
  const [configData, setConfigData] = useState(marketConfig);
  // const {localMarketData, localMarketDataSortedKeys} = useLocalMarkets(); // commenting because we are already calling same API from useLocalMarkets hook
  const [localMarketSortKeys, setLocalMarketSortKeys] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isGetLanguageLoading, setIsGetLanguageLoading] = useState(false);
  const [multilingualVersions, setMultilingualVersions] = useState({});

  useEffect(() => {
    const configData = JSON.parse(localStorage.getItem('configData'));
    setConfigData(configData);
  }, []);

  useEffect(() => {
    const newMultilingualVersions = {};

    language.forEach(l => {
      const versionObject = courseData?.multilingualDetails?.find(detail => detail?.locale === l?.locale);
      const version = versionObject ? versionObject.version : 0;
      newMultilingualVersions[l?.locale] = version;
    });

    setMultilingualVersions(newMultilingualVersions);
  }, [courseData?.multilingualDetails, language])

  // Moved this logic to getLanguages function
  // useEffect(() => {
  //   const localMarketSortKeys = {
  //     [configData.locale]: 1,
  //   };

  //   // Increment priorities of existing keys and add them to the new object
  //   for (let key in localMarketDataSortedKeys) {
  //     localMarketSortKeys[key] = localMarketDataSortedKeys[key] + 1;
  //   }
  //   setLocalMarketSortKeys(localMarketSortKeys);
  // }, [localMarketDataSortedKeys, addedTranslationListState]);

  const LanguageComputation = (computedList) => {
    let updatedMultiLinguialDetails = addedTranslationListState.map((detail) => {
      return {
        ...detail,
        languageName: computedList[detail?.locale],
      };
    });

    if (addedTranslationListState[1]?.languageName === undefined)
      setNewTranslationList(updatedMultiLinguialDetails);
  };

  const getLanguage = async () => {
    setIsGetLanguageLoading(true);
    let apiData;
    const configData = JSON.parse(localStorage.getItem('configData'));

    if (language.length === 0) {
      apiData = await makeRequest({
        method: 'GET',
        endpoint: `/api/v1/market-languages?locale=${configData?.locale}`,
      });
    }

    if (apiData) {
      const computedList = apiData.data?.otherLocales.reduce((acc, current) => {
        acc[current.locale] = current.title;
        return acc;
      }, {});

      let count = 1;
      const computedListSorted = await apiData.data?.otherLocales.reduce((acc, current) => {
        acc[current.locale] = count++;
        return acc;
      }, {});

      const localMarketSortKeys = { [configData.locale]: 1 };

      // Increment priorities of existing keys and add them to the new object
      for (let key in computedListSorted) {
        localMarketSortKeys[key] = computedListSorted[key] + 1;
      }
      setLocalMarketSortKeys(localMarketSortKeys);

      if (addedTranslationListState.length !== 0) {
        const tempLang = apiData?.data?.otherLocales?.map((lang) => {
          return {
            ...lang,
            isAdded: addedTranslationListState.some((langA) => {
              return langA?.locale === lang?.locale;
            }),
          };
        });
        setLanguage(tempLang);
        LanguageComputation(computedList);
      }
    }
    setIsGetLanguageLoading(false);
  };
  const postLanguage = async (item) => {
    let translationLanguage = JSON.parse(localStorage.getItem('languageName'));
    translationLanguage =
      translationLanguage && translationLanguage[0] ? translationLanguage[0] : translationLanguage;

    let prevData = localStorage.getItem('previousLanguage');
    prevData = JSON.parse(prevData);
    let payload = item;

    let endPoint =
      isLp === false
        ? `/admin/api/v1/courses/${courseData?.courseId}/localize?locale=${selectLanguage?.locale}`
        : `/admin/api/v1/learning-paths/${learningPathId}/localize?locale=${selectLanguage?.locale}`;

    const apiData = await makeRequest({
      method: 'PUT',
      endpoint: endPoint,
      body: payload,
    });
    if (apiData !== 'ERR_NETWORK' && apiData !== 'ERR_BAD_REQUEST' && apiData) {
      setNewTranslationList(addedTranslationListState);
      setSelectedLanguage({});
      return apiData;
    } else if (apiData === 'ERR_NETWORK' || apiData === 'ERR_BAD_REQUEST') {
      setNotificationData({
        show: true,
        type: 'WARNING',
        title: 'Failed to add Language',
        description: 'Something went wrong. Please try again',
      });
    }
  };

  const deleteLang = async (item) => {
    let endPoint =
      isLp === false
        ? `/admin/api/v1/courses/${courseData?.courseId}/unlocalize?locale=${item?.locale}`
        : `/admin/api/v1/learning-paths/${learningPathId}/unlocalize?locale=${item?.locale}`;

    const apiData = await makeRequest({
      method: 'POST',
      endpoint: endPoint,
      body: item,
    });
    if (isDrawer === false || isDelete) {
      if (isDelete) {
        if (apiData !== 'ERR_NETWORK' && apiData !== 'ERR_BAD_REQUEST' && apiData) {
          setNotificationData({
            show: true,
            type: 'SUCCESS',
            title: `${item.languageName} language Information Removed.`,
            description: `${item.languageName} Language Information has been successfully removed.`,
          });
          return apiData;
        } else if (apiData === 'ERR_NETWORK' || apiData === 'ERR_BAD_REQUEST') {
          setNotificationData({
            show: true,
            type: 'WARNING',
            title: 'Failed to delete Language',
            description: 'Something went wrong. Please try again',
          });
        }
      } else {
        return apiData;
      }
    }
  };
  const ascendingOrder = (items) => {
    items.sort((a, b) => localMarketSortKeys[a.locale] - localMarketSortKeys[b.locale]);
  };

  useEffect(async () => {
    await getLanguage();
    ascendingOrder(addedTranslationListState);
  }, [addedTranslationListState]);

  useEffect(() => {
    if (courseData?.multilingualDetails?.length === 0 && addedTranslationListState.length === 0) {
      setNewTranslationList([
        {
          id: 1,
          title: courseData?.primaryTitle,
          description: courseData?.primaryDescription,
          speaker: courseData?.primarySpeaker,
          locale: configData?.locale,
          language: configData?.locale,
          adaptFileName: courseData?.adaptFileName,
          version: courseData?.version || 0,
        },
      ]);
    } else {
      let updatedList = addedTranslationListState.filter(
        (item) => item.locale !== configData?.locale,
      );
      setNewTranslationList([
        {
          id: 1,
          title: courseData?.primaryTitle,
          description: courseData?.primaryDescription,
          speaker: courseData?.primarySpeaker,
          locale: configData?.locale,
          language: configData?.locale,
          adaptFileName: courseData?.adaptFileName,
          version: courseData?.version || 0,
        },
        ...updatedList,
      ]);
    }
    if (
      (courseData?.multilingualDetails?.length > 1 || isPrimaryLPAdded || isPrimaryCourseAdded) &&
      !isEditCourse &&
      showPrimaryNotification
    ) {
      setNotificationData({
        show: true,
        type: 'SUCCESS',
        title: `${
          configData?.primaryLanguage ? configData?.primaryLanguage : ''
        } Primary Language Information Added`,
        description: `${
          configData?.primaryLanguage ? configData?.primaryLanguage : ''
        } Language Information has been successfully added`,
      });
      setShowPrimaryNotification(false);
    }

    return () => {
      if (translationFor == 'course wizard') {
        setPrimaryCourseAdded(false);
      } else {
        setPrimaryLPAdded(false);
      }
    };
  }, []);

  useEffect(() => {
    let prevData = [];
    if (courseData?.multilingualDetails !== undefined) {
      prevData = courseData?.multilingualDetails?.map(
        (item, key) => ({...item, id: key == 0 ? key + 2 : key + 1} || []),
      );
    } else {
      prevData = [];
    }
    if (addedTranslationListState && addedTranslationListState.length <= 0) {
      setNewTranslationList([
        {
          id: 1,
          title: courseData?.primaryTitle,
          description: courseData?.primaryDescription,
          speaker: courseData?.primarySpeaker,
          language: configData?.locale,
          locale: configData?.locale,
          adaptFileName: courseData?.adaptFileName,
          version: courseData?.version || 0,
        },
        ...prevData,
        ...addedTranslationListState,
      ]);
    }
  }, [data]);

  const handleCloseDeleteModal = (val) => {
    setDelete(val);
    setDeleteRecord(val);
    setSelectedLanguage({});
    setAdaptFile('');
    setVersion(0);
    setFileDataTemp(null);
  };

  const handleSaveDeleteChanges = async (val) => {
    setIsLoading(true);
    setDelete(val);

    const res = await deleteLang(selectLanguage);
    if (res?.status == 'Success') {
      const tempData =
        addedTranslationListState &&
        addedTranslationListState.filter((item) => item?.locale !== selectLanguage?.locale);
        
      setMultilangugalState('multilingualDetails', tempData);
      setMultilingualVersions(prev => ({...prev, [selectLanguage?.locale]: 0}));
      setNewTranslationList(tempData);
    }

    setDeleteRecord(val);
    setSelectedLanguage({});
    setReplaceFile(false);

    const langDetails = language.map((lang) => {
      if (lang.locale === selectLanguage.locale) {
        return {
          ...lang,
          isAdded: false,
        };
      }
      return {
        ...lang,
      };
    });

    setLanguage(langDetails);
    setIsLoading(false);
  };
  const handleDelete = (item, val) => {
    setSelectedLanguage(item);
    setDelete(val);
    setDeleteRecord(val);
    if (isDrawer) {
      setDrawer(false);
    }
    let previousDeletedLanguage = {...item};
    localStorage.setItem('previousDeletedLanguage', JSON.stringify(previousDeletedLanguage));
  };

  const handleCloseConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };
  const handleConfirmChanges = () => {
    let translationLanguage = JSON.parse(localStorage.getItem('languageName'));
    translationLanguage =
      translationLanguage && translationLanguage[0] ? translationLanguage[0] : translationLanguage;

    let item = {locale: translationLanguage?.language};
    if (isEdit == false) {
      deleteLang(item);
    }

    setDrawer(false);
    setEdit(false);
    setAdd(false);
    setSelectedLanguage({});
    setAdaptFileState('INPROGRESS');
    setAdaptFile('');
    setIsLocale(false);
    setVersion(0);
    setFileDataTemp(null);
    setIsConfirmModalOpen(false);
  };

  const handleAddLanguage = () => {
    setDrawer(!isDrawer);
    setAdd(true);

    setSelectedLanguage({});
    setAdaptFileState('INPROGRESS');
    setAdaptFile('');
    setIsLocale(false);
    setVersion(0);
    setFileDataTemp(null);
    setIsConfirmModalOpen(false);
  };
  const closeDrawerModal = () => {
    let translationLanguage = JSON.parse(localStorage.getItem('languageName'));
    translationLanguage =
      translationLanguage && translationLanguage[0] ? translationLanguage[0] : translationLanguage;

    let item = {locale: translationLanguage?.language};
    if (isEdit) {
      setIsConfirmModalOpen(true);
    } else {
      deleteLang(item);
      setDrawer(false);
    }
  };

  const handleEditChange = (item, flag) => {
    setDrawer(true);
    setEdit(flag);
    setSelectedLanguage(item);
    localStorage.setItem('languageName', JSON.stringify(language));
    let fileName = courseData?.multilingualDetails?.map((item) => {
      if (item?.locale === language[0]?.locale) {
        return item?.adaptFileName;
      }
    });
    let prevData = JSON.parse(localStorage.getItem('previousLanguage'));
    if (prevData?.id !== item?.id) {
      let previousLanguage = {
        ...item,
        adaptFileName: fileName[0]
          ? fileName[0]
          : prevData?.adaptFileName
          ? prevData?.adaptFileName
          : '',
      };
      localStorage.setItem('previousLanguage', JSON.stringify(previousLanguage));
    }
  };

  /*
    Start Save Translation Changes
  */
  const saveChanges = async (item, fileName = '') => {
    setIsLoading(true);

    item = {
      ...item,
      locale: selectLanguage?.language
        ? selectLanguage?.language
        : selectLanguage?.locale
        ? selectLanguage?.locale
        : item?.language,
      languageName: selectLanguage?.languageName
        ? selectLanguage?.languageName
        : item?.languageName,
      adaptFileName: fileDataTemp?.name,
      version: multilingualVersions[selectLanguage?.locale],
    };

    let prevAdaptFile =
      addedTranslationListState &&
      addedTranslationListState.map((prevData) => {
        if (prevData?.locale === item?.locale) {
          return prevData?.adaptFileName;
        }
      });

    let mainDataFileName = courseData?.multilingualDetails?.map((item) => {
      if (item?.locale === language[0]?.locale) {
        return item?.adaptFileName;
      }
    });

    prevAdaptFile = prevAdaptFile.filter((filterData) => filterData !== undefined);
    item = {
      ...item,
      adaptFileName: fileName?.name
        ? fileName?.name
        : Array.isArray(prevAdaptFile) && prevAdaptFile?.length > 0
        ? prevAdaptFile?.flat(1)[0]
        : Array.isArray(mainDataFileName) && mainDataFileName?.length > 0
        ? mainDataFileName[0]
        : prevAdaptFile
        ? prevAdaptFile
        : '',
    };

    if (isEdit) {
      addedTranslationListState = addedTranslationListState.map((value) => {
        return (item?.language !== undefined && value?.language === item?.language) ||
          (item?.locale !== undefined && value?.locale === item?.locale)
          ? item
          : value;
      });

      setNewTranslationList(addedTranslationListState);
      setMultilangugalState('multilingualDetails', addedTranslationListState);
      // localStorage.setItem('previousLanguage', JSON.stringify(item));
    } else {
      const langDetails = language.map((lang) => {
        if (lang.locale === item.language) {
          return {
            ...lang,
            isAdded: true,
          };
        }
        return {
          ...lang,
        };
      });

      item = {
        ...item,
        locale: selectLanguage?.language ? selectLanguage?.language : item?.language,
        languageName: selectLanguage?.languageName
          ? selectLanguage?.languageName
          : item?.languageName,
      };

      addedTranslationListState.push(item);
      localStorage.setItem('translationListCount', addedTranslationListState?.length);
      setLanguage(langDetails);
      setNewTranslationList(addedTranslationListState);
      setMultilangugalState('multilingualDetails', addedTranslationListState);
    }

    setDrawer(false);

    if (courseData && (courseData.courseId || courseData.learningPathId)) {
      setEdit(false);
      setAdd(false);
      setAdaptFile('');
      setVersion(0);
      setIsLocale(false);
      ascendingOrder(addedTranslationListState);

      const result = await postLanguage(item);
      if (result !== 'ERR_BAD_REQUEST' && result !== 'ERR_REQUEST') {
        
        setNotificationData({
          show: true,
          type: 'SUCCESS',
          title: isEdit
            ? 'Language Information Edited Successfully'
            : 'Language Information Added Successfully',
          description: isEdit
            ? 'Language Information has been successfully edited'
            : 'Language Information has been successfully added',
        });
      }
    }

    setIsLoading(false);
  };
  /*
    End Save Translation Changes
  */

  useEffect(() => {
    const isEveryLangAdded = Array.isArray(language) && language.every((lang) => lang.isAdded);
    setIsAllLangDisabled(isEveryLangAdded);
  }, [language]);

  /* 
    ---------------------------------------- 
    Course File Starts Here
    ---------------------------------------- 
  */
  function setCookie(cookieKey, value, expirationTimeInMinute, domain = '.amway.com', path = '/') {
    try {
      let expires = '';
      if (expirationTimeInMinute) {
        const date = new Date();
        date.setTime(date.getTime() + expirationTimeInMinute * 60000);
        expires = `; expires=${date.toGMTString()}`;
      }

      let _cookieString = '';
      if (cookieKey && value) {
        _cookieString = `${cookieKey}=${value}`;
      } else if (expires) {
        _cookieString += `${expires}=${value}`;
      }

      if (window.location.hostname === 'localhost') {
        document.cookie = `${_cookieString}; domain=localhost; path=${path}`;
      } else {
        document.cookie = `${_cookieString}; domain=${domain}; path=${path}; Secure`;
      }
    } catch (err) {
      //catch
      console.log(err);
    }
  }

  const openInNewTab = (url) => {
    if (previewData !== null && previewData?.data?.version) {
      const newWindow = window.open(previewData?.data?.courseUrl, '_blank', 'noopener,noreferrer');
      const _cookies = previewData?.data?.cookies;
      const _lrsCookies = previewData?.data?.lrsCookies;
      if (_cookies) {
        for (const item of _cookies) {
          setCookie(
            item?.cookieName,
            item?.cookieValue,
            item?.cookieExpirationInMinutes,
            previewData?.data?.domain,
            previewData?.data?.coursePath,
          );
        }
      }
      if (_lrsCookies) {
        for (const item of _lrsCookies) {
          setCookie(
            item?.cookieName,
            item?.cookieValue,
            item?.cookieExpirationInMinutes,
            previewData?.data?.domain,
            previewData?.data?.dataPath,
          );
        }
      }

      setPublishButtonAfterPreview(false);
      // allCheckFunc();
      if (newWindow) {
        newWindow.opener = null;
      }
    }
    setOpenPreviewAdaptFile(false);
  };

  useEffect(() => {
    openPreviewAdaptFile && openInNewTab('url');
  }, [openPreviewAdaptFile]);

  const handleDataFill = (name, value) => {
    if (name === 'duration') {
      let limit = 25000;
      if (value > limit) {
        value = limit;
      }
    }
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // editState ? editSaveAndExitEnable(data) : null;
  };
  const handleDataFillMultiLingual = (name, value, eleData) => {
    if (name === 'duration') {
      let limit = 25000;
      if (value > limit) {
        value = limit;
      }
    }
    let tempObj =
      eleData?.multilingualDetails && eleData?.multilingualDetails[0]
        ? eleData?.multilingualDetails[0]
        : {};
    tempObj[name] = value;

    setData((prevData) => ({
      ...prevData,
      multilingualDetails: [tempObj],
    }));
    // editState ? editSaveAndExitEnable(data) : null;
  };

  const previewAdaptFileApi = async (
    versionNumber,
    id,
    locale,
    file,
    tempData = null,
    newCourseIDData = null,
  ) => {
    //let versionNum = versionNumber ? versionNumber : data?.version;
    // let versionNum = 0;
    // if (locale == configData?.locale) {
    //   versionNum = versionNumber ? versionNumber : data.version ? Number(data.version) : 0;
    // }
    const courseId = data?.courseId || id;
    let translationLanguage = JSON.parse(localStorage.getItem('languageName'));
    translationLanguage =
      translationLanguage && translationLanguage[0] ? translationLanguage[0] : translationLanguage;
    if (versionNumber >= 2) {
      setReplaceFile(true);
    }

    const apiData = await makeRequest({
      method: 'GET',
      endpoint: `/admin/api/v1/courses/${courseId}/preview?version=${
        versionNumber || '0'
      }&locale=${selectLanguage?.locale}`,
    });

    if(apiData?.data?.version != versionNumber && apiData?.status?.toLowerCase() === 'success' && !apiData?.data?.isErrorOccuredInHosting){
      await previewAdaptFileApi(
        versionNumber,
        id,
        locale,
        file,
        tempData = null,
        newCourseIDData = null,
      );
    } else if(apiData?.data?.version == versionNumber && apiData?.status?.toLowerCase() === 'success' && !apiData?.data?.isErrorOccuredInHosting) {
      setPreviewData(apiData);
      setFileDataTemp(file);
      setAdaptFileState('SUCCESS');
      setVersion(versionNumber);
      setReplaceFile(true);

      const langToUpdate = courseData?.multilingualDetails?.findIndex(l => l?.locale === selectLanguage?.locale);

      if(langToUpdate >= 0){
        const multilingualDetailsCpy = courseData?.multilingualDetails?.length ? [...courseData.multilingualDetails] : [];

        multilingualDetailsCpy[langToUpdate] = {...multilingualDetailsCpy[langToUpdate], version: versionNumber};

        setMultilangugalState('multilingualDetails', multilingualDetailsCpy);        
      }
      
      setNotificationData({
        show: true,
        type: 'SUCCESS',
        title: `${
          translationLanguage?.languageName
            ? translationLanguage?.languageName
            : selectLanguage?.languageName
        } Course File ${
          (versionNumber == 0 || versionNumber == 1)
            ? 'Uploaded'
            : 'Replaced'
        } Successfully`,
        description: `${file?.name} has been uploaded.`,
      });
    } else {
      setAdaptFileState('FAILURE');
      setReplaceFile(false);
      setNotificationData({
          show: true,
          type: 'WARNING',
          title: 'File Hosting Failed',
          description:
            'Course file hosting failed. Please re-uplaod the course file to publish this course. Click on Course Information to access the upload button.',
        });
    }

    // OLD Logic of the course file upload/relace
    // if (apiData?.data) {
    //   setVersion(apiData?.data?.version);
    //   if (
    //     apiData?.status?.toLowerCase() === 'success' &&
    //     !apiData?.data?.isErrorOccuredInHosting &&
    //     apiData?.data?.version >= versionNum
    //   ) {
    //     setPreviewData(apiData);
    //     setAdaptFileState('SUCCESS');
    //     // if (allCheckFunc() && mainState) {
    //     //   setNextButtonDisable(false);
    //     // } else if (allCheckFunc()) {
    //     //   setNextButtonDisable(false);
    //     // }
    //     if (locale == configData?.locale) {
    //       // setData((prevData) => ({
    //       //   ...prevData,
    //       //   adaptFileName: file?.name,
    //       // }));
    //       tempData.version = versionNum;
    //       // tempData.adaptFileName = file?.name;
    //       handleDataFill('version', versionNum);
    //       // handleDataFill('adaptFileName', file?.name);
    //     }
    //     // setPublishButtonDisableEdit(false);
    //     if (apiData?.data?.version > versionNum) {
    //       if (!apiData?.isErrorOccuredInHosting) {
    //         setVersion(apiData?.data?.version + 1);
    //         await previewAdaptFileApi(
    //           apiData?.data?.version + 1,
    //           courseId,
    //           locale,
    //           file,
    //           tempData,
    //           newCourseIDData,
    //         );
    //       }
    //       if (locale == configData?.locale) {
    //         versionNum = apiData?.data?.version;
    //         // setData((prevData) => ({
    //         //   ...prevData,
    //         //   adaptFileName: file?.name,
    //         // }));
    //         handleDataFill('version', apiData?.data?.version);
    //         setFileDataTemp(file);
    //       }
    //     }

    //     if (locale === configData?.locale) {
    //       setFileDataTemp(file);
    //       setNotificationData({
    //         show: true,
    //         type: 'SUCCESS',
    //         title: `${
    //           translationLanguage?.languageName
    //             ? translationLanguage?.languageName
    //             : selectLanguage?.languageName
    //         } Course File ${
    //           (version == 0 || version == 1) && !fileDataTemp?.name && !isEdit
    //             ? 'Uploaded'
    //             : 'Replaced'
    //         } Successfully`,
    //         description: `${file?.name} has been uploaded.`,
    //       });
    //     }
    //     // await publishEditCourseDetails(
    //     //   false,
    //     //   false,
    //     //   locale,
    //     //   file,
    //     //   false,
    //     //   tempData,
    //     //   addUserGroupList,
    //     //   deleteUserGroupList,
    //     //   newCourseIDData,
    //     // );
    //     // await fetchSelectedCourse(newCourseIDData.courseId);
    //   } else if (
    //     (!apiData?.isErrorOccuredInHosting && apiData?.data?.version != versionNum) ||
    //     apiData?.data?.version < versionNum
    //   ) {
    //     await previewAdaptFileApi(
    //       versionNum >= 2 ? versionNum - 1 : versionNum,
    //       courseId,
    //       locale,
    //       file,
    //       tempData,
    //       newCourseIDData,
    //     );
    //   } else if (
    //     apiData?.status?.toLowerCase() === 'success' &&
    //     apiData?.data?.isErrorOccuredInHosting &&
    //     apiData?.data?.version >= versionNum
    //   ) {
    //     setAdaptFileState('FAILURE');
    //     // setNotificationData({
    //     //   show: true,
    //     //   type: 'WARNING',
    //     //   title: 'File Hosting Failed',
    //     //   description:
    //     //     'Course file hosting failed. Please re-uplaod the course file to publish this course. Click on Course Information to access the upload button.',
    //     // });
    //     if (apiData?.data?.version > versionNum) {
    //       if (locale == configData?.locale) {
    //         versionNum = apiData?.data?.version;
    //         setData((prevData) => ({
    //           ...prevData,
    //           version: apiData?.data?.version,
    //         }));
    //         tempData.version = versionNum;
    //       }
    //     }
    //     // await publishEditCourseDetails(
    //     //   false,
    //     //   false,
    //     //   locale,
    //     //   file,
    //     //   true,
    //     //   tempData,
    //     //   addUserGroupList,
    //     //   deleteUserGroupList,
    //     //   newCourseIDData,
    //     // );
    //   }
    // }
    // return;
  };

  // File Upload to S3 functionality
  const syncToS3 = async (url, fileData, id, locale, tempData, newCourseIDData) => {
    let translationLanguage = JSON.parse(localStorage.getItem('languageName'));
    translationLanguage =
      translationLanguage && translationLanguage[0] ? translationLanguage[0] : translationLanguage;

    await fetch(url, {
      method: 'PUT',
      body: fileData,
      headers: {
        'Content-Type': 'application/zip',
        'Content-Encoding': 'gzip',
      },
    })
      .then(async (res) => {

        const versionNum = multilingualVersions[selectLanguage?.locale] + 1;
        setMultilingualVersions(prev => ({...prev, [selectLanguage?.locale]: versionNum}));

        if (res?.statusText === 'OK') {
          // let versionNum = Number(0);
          // if (locale == configData?.locale) {
          //   versionNum = data?.version ? Number(data?.version) : Number(0);
          // }
          // await fetchSelectedCourse(data.courseId);
          // versionNum = versionNum + 1;

          setOpenAdaptFileModal(true);
          if (editState) {
            await previewAdaptFileApi(versionNum, id, locale, fileData, tempData, newCourseIDData);
            //await publishEditCourseDetails(false, false, locale, fileData);
            // updateCourseDetails(data?.courseId,{adaptFileName:fileData?.name,courseState:data?.courseState
            //  ,version :versionNum
            // })
            //publishEditCourseDetails(false);
          } else {
            await previewAdaptFileApi(versionNum, id, locale, fileData, tempData, newCourseIDData);
            //await publishEditCourseDetails(false, false, locale, fileData);
            setPreviewAdaptFileFlag(true);
          }
          // setPublishButtonAfterPreview(true);
        } else {
         
          if (locale == configData?.locale) {
            setNotificationData({
              show: true,
              type: 'WARNING',
              title: `${
                translationLanguage?.languageName ? translationLanguage?.languageName : ''
              } Course file ${
                (versionNum == 0 || versionNum == 1)
                  ? 'Uploading'
                  : 'Replacing'
              } Failed`,
              description: `${fileData?.name}  has not been uploaded. Please re-upload the Course file.`,
            });
          }
        }
        if (locale == configData?.locale) {
          setUploadingFileButton(false);
          setUploadingFileButtonHindi(false);
          setReplaceFile(false);
        }

        // setPublishButtonDisableEdit(false);
        // setBtn2SaveAndExitDisable(false);
        setDisableImageLib(false);
        // setHeaderButtons(true);
      })
      .catch((error) => {
        setUploadingFileButton(false);
        setUploadingFileButtonHindi(false);
        // setSaveAndExitDisable(false);
        // setNextButtonDisable(false);
        // setPublishButtonDisableEdit(false);
        // setBtn2SaveAndExitDisable(false);
        setDisableImageLib(false);
        setReplaceFile(false);
        // setHeaderButtons(true);
      });
  };
  const uploadToS3 = async (fileData, locale, id, tempData, newCourseIDData) => {
    // setSaveAndExitDisable(true);
    // setNextButtonDisable(true);
    // setPublishButtonDisableEdit(true);
    // setBtn2SaveAndExitDisable(true);
    setDisableImageLib(true);
    if (locale == configData?.locale) {
      setUploadingFileButton(true);
    }
    // setHeaderButtons(false);
    const courseId = (newCourseID && newCourseID.courseId) || id;
    let translationLanguage = JSON.parse(localStorage.getItem('languageName'));
    translationLanguage =
      translationLanguage && translationLanguage[0] ? translationLanguage[0] : translationLanguage;

    const apiData = await makeRequest({
      method: 'GET',
      endpoint: `/admin/api/v1/courses/${courseId}/upload?locale=${selectLanguage?.locale}`,
    });
    if (apiData) {
      let url = apiData?.data?.preSignedUrl;
      await syncToS3(url, fileData, courseId, locale, tempData, newCourseIDData);

      // await fetchSelectedCourse(data.courseId);
    } else {
      if (locale == configData?.locale) {
        setUploadingFileButton(false);
        setUploadingFileButtonHindi(false);
      }
    }
  };

  const localeLanguage = async () => {
    let translationLanguage = JSON.parse(localStorage.getItem('languageName'));
    translationLanguage =
      translationLanguage && translationLanguage[0] ? translationLanguage[0] : translationLanguage;
    let payload = {locale: translationLanguage?.language};
    let endPoint =
      isLp === false
        ? `/admin/api/v1/courses/${courseData?.courseId}/localize?locale=${selectLanguage?.locale}`
        : `/admin/api/v1/learning-paths/${learningPathId}/localize?locale=${selectLanguage?.locale}`;

    const apiData = await makeRequest({
      method: 'PUT',
      endpoint: endPoint,
      body: payload,
    });
    if (apiData !== 'ERR_NETWORK' && apiData !== 'ERR_BAD_REQUEST' && apiData) {
      setIsLocale(true);
      return apiData;
    } else if (apiData === 'ERR_NETWORK' || apiData === 'ERR_BAD_REQUEST') {
      setIsLocale(false);
      setNotificationData({
        show: true,
        type: 'WARNING',
        title: 'Failed to add Language',
        description: 'Something went wrong. Please try again',
      });
    }
  };

  const handleTranslationFileUpload = async ({event, courseID, version, selectedLanguage}) => {
    const versionNum = multilingualVersions[selectLanguage?.locale] + 1;

    if (versionNum >= 2) {
      setReplaceFile(true);
    } else {
      setReplaceFile(false);
    }

    //const fullFileName = event.target.files[0].name.split('.')
    //Size converted to MB
    setUploadingFileButton(true);
    await localeLanguage();

    let _size = event?.target?.files[0].size.toString();
    /**
     * File sizes in kB and MB Until the file size in bytes would be upto 7 digits
     * if the bytes would have 7 to 10 digits, we would have to divide it by 10**3(n) where n is the appending action
     */
    if (_size.length < 7) {
      _size = Math.round(_size / 1024).toFixed(); // will return size in KB
    }
    _size = (Math.round(_size / 1024) / 1000).toFixed(2); // will return size in MB

    if (
      event?.target?.files[0]?.name?.includes('.zip') &&
      (event?.target?.files[0]?.type == 'application/zip' ||
        event?.target?.files[0]?.type == 'application/x-zip-compressed') &&
      _size <= 100
    ) {
      const langSelect = {...selectLanguage, adaptFileName: event?.target?.files[0]?.name};
      setSelectedLanguage(langSelect);
      setFileDataTemp(event.target.files[0]);

      setDisableImageLib(true);

      // setHeaderButtons(false);
      setNotificationData({
        show: true,
        type: 'UPLOADING',
        title: `${selectedLanguage} Course File ${
          (versionNum == 0 || versionNum == 1) ? 'upload' : 'replace'
        } has started`,
        description: `${event.target.files[0]?.name} upload in-progress. `,
      });

      if (courseID != null && courseID != undefined) {
        await uploadToS3(
          event?.target?.files[0],
          configData?.locale,
          courseData?.courseId,
          courseData,
          newCourseID,
        );
      }

      event.target.value = null;
      setUploadingFileButton(false);
      setReplaceFile(false);
    } else {
      setNotificationData({
        show: true,
        type: 'WARNING',
        title: 'Invalid File',
        // description: `Please upload .zip, .rar or .7zip files only!`,
        description: 'Please upload only .zip files of size 100MB max!',
      });
      setUploadingFileButton(false);
      setReplaceFile(false);
    }
  };
  /* 
  ----------------------------------- 
  Course File Ends Here
  ----------------------------------- 
  */

  return (
    <>
      {sessionStorage.setItem('translationData', JSON.stringify(addedTranslationListState))}
      {sessionStorage.setItem('translationLang', JSON.stringify(addedTranslationListState))}
      <div
        className={[
          styles.translation_wrapper,
          (isEditCourse &&
            translationFor == 'course wizard' &&
            courseData?.courseState?.toLowerCase() === 'live') ||
          courseData?.courseState?.toLowerCase() === 'offline'
            ? styles.height_auto
            : '',
          '',
        ].join(' ')}
      >
        <div className={[styles.inner_wrapper, styles.translation_inner_wrapper, ''].join(' ')}>
          <div className={[styles.main_wrapper, ''].join(' ')}>
            {/* Top Area Starts */}
            <div className={styles.top_area}>
              <div className={styles.section_wrapper}>
                <div className={styles.title}>Translation</div>
                <div
                  className={`${styles.btn_wrapper} ${styles.desktop_lang} ${
                    courseData?.courseState?.toLowerCase() === 'live' ? styles.disabled : ''
                  }`}
                  onClick={handleAddLanguage}
                >
                  <div className={styles.btn}>
                    Add Language
                    <div className={styles.add_icon} />
                  </div>
                </div>
              </div>
              <div className={styles.sub_title}>
                Add multiple language support to this {isLp ? 'learning path' : 'course'} by adding a translation for the
                Title, Description and Speaker (if applicable).
              </div>
              <div
                className={`${styles.btn_wrapper} ${styles.mob_lang} ${
                  courseData?.courseState?.toLowerCase() === 'live' ? styles.disabled : ''
                }`}
                onClick={handleAddLanguage}
              >
                <div className={styles.btn}>
                  Add Language
                  <div className={styles.add_icon} />
                </div>
              </div>
            </div>
            {/* Top Area Ends */}

            {/* Cards Starts */}
            {isLoading || isGetLanguageLoading ? (
              <div className={styles.card_wrapper}>
                <LoadingShimmer count={3} flex30 />
              </div>
            ) : (
              <div
                className={`${styles.card_wrapper} ${
                  // selectLanguage?.id && !isDelete ? styles.active_drawer : ''
                  isDrawer && !isDelete ? styles.active_drawer : ''
                }`}
              >
                {addedTranslationListState?.map((item, index) =>
                  // When user is on Course wizard & it is in draft state not live or offline
                  translationFor === 'course wizard' ? (
                    (item?.locale !== configData?.locale ||
                      courseData?.courseState?.toLowerCase() === 'draft') &&
                    (courseData?.courseState?.toLowerCase() !== 'live' ||
                      courseData?.courseState?.toLowerCase() !== 'offline') ? (
                      <div
                        key={index}
                        className={`${styles.card_block} col-md-12 col-lg-${
                          isDrawer && !isDelete ? '6' : '4'
                        }`}
                      >
                        <div
                          className={`${styles.card} ${
                            item?.languageName === selectLanguage?.languageName
                              ? styles.selected
                              : ''
                          }`}
                        >
                          <div className={styles.language_info}>
                            <div className={styles.language}>
                              {item?.language?.toLowerCase() == configData?.locale?.toLowerCase()
                                ? configData?.primaryLanguage
                                : item?.languageName}
                            </div>

                            {item?.language == configData?.locale ? (
                              <div className={styles.primary_wrapper}>
                                <div className={styles.star} />
                                <div className={styles.type}>Primary</div>
                              </div>
                            ) : (
                              <div className={styles.action_wrapper}>
                                <div
                                  className={`${styles.edit_btn} ${
                                    courseData?.courseState?.toLowerCase() === 'live'
                                      ? styles.disabled
                                      : ''
                                  }`}
                                  onClick={() => handleEditChange(item, true)}
                                />
                                <div className={styles.seperator} />
                                <div
                                  className={`${styles.delete_btn} ${
                                    courseData?.courseState?.toLowerCase() === 'live'
                                      ? styles.disabled
                                      : ''
                                  }`}
                                  onClick={() => handleDelete(item, true)}
                                />
                              </div>
                            )}
                          </div>
                          <div className={styles.title}>{item && item?.title && item?.title}</div>
                          <div className={styles.author}>
                            {item && item?.speaker && item?.speaker}
                          </div>
                          <div className={styles.description}>
                            {item && item?.description && item?.description}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )
                  ) : (
                    <div
                      key={index}
                      className={`${styles.card_block} col-md-12 col-lg-${
                        isDrawer && !isDelete ? '6' : '4'
                      }`}
                    >
                      <div
                        className={`${styles.card} ${
                          item?.languageName === selectLanguage?.languageName ? styles.selected : ''
                        }`}
                      >
                        <div className={styles.language_info}>
                          <div className={styles.language}>
                            {item?.language?.toLowerCase() == configData?.locale?.toLowerCase()
                              ? configData?.primaryLanguage
                              : item?.languageName}
                          </div>

                          {item?.language == configData?.locale ? (
                            <div className={styles.primary_wrapper}>
                              <div className={styles.star} />
                              <div className={styles.type}>Primary</div>
                            </div>
                          ) : (
                            <div className={styles.action_wrapper}>
                              <div
                                className={`${styles.edit_btn} ${
                                  courseData?.courseState?.toLowerCase() === 'live'
                                    ? styles.disabled
                                    : ''
                                }`}
                                onClick={() => handleEditChange(item, true)}
                              />
                              <div className={styles.seperator} />
                              <div
                                className={`${styles.delete_btn} ${
                                  courseData?.courseState?.toLowerCase() === 'live'
                                    ? styles.disabled
                                    : ''
                                }`}
                                onClick={() => handleDelete(item, true)}
                              />
                            </div>
                          )}
                        </div>
                        <div className={styles.title}>{item && item?.title && item?.title}</div>
                        <div className={styles.author}>
                          {item && item?.speaker && item?.speaker}
                        </div>
                        <div className={styles.description}>
                          {item && item?.description && item?.description}
                        </div>
                      </div>
                    </div>
                  ),
                )}
              </div>
            )}
            {/* Cards Ends */}
          </div>
        </div>

        {/* Delete Modal */}
        {isDelete ? (
          <TranslationDeleteModal
            heading={'Confirm Removal'}
            subHeading={`You are about to remove ${
              selectLanguage.languageName
            } translation from this ${
              isLp ? 'Learning Path' : 'Course'
            }. This action cannot be reversed and you will have to re-enter any information manually`}
            isDeleteRecord={isDeleteRecord}
            Notification={Notification}
            Background={Background}
            ModalWrapper={ModalWrapper}
            handleCloseDeleteModal={handleCloseDeleteModal}
            handleSaveDeleteChanges={handleSaveDeleteChanges}
          />
        ) : null}
        {/* Delete Modal */}

        {/* Translation Details Drawer */}
        {isDrawer ? (
          <TranslationDetailsDrawerComponent
            language={language}
            closeDrawerModal={closeDrawerModal}
            saveChanges={saveChanges}
            isEdit={isEdit}
            isAdd={isAdd}
            selectLanguage={selectLanguage}
            translationFor={translationFor}
            placeholders={placeholders}
            data={courseData}
            newCourseID={newCourseID}
            isLp={isLp}
            courseData={courseData}
            learningPathId={learningPathId}
            handleTranslationFileUpload={handleTranslationFileUpload}
            adaptFileButtonState={adaptFileButtonState}
            uploadingFileButton={uploadingFileButton}
            uploadingFileButtonHindi={uploadingFileButtonHindi}
            fileDataTemp={fileDataTemp}
            fileDataTempHindi={fileDataTempHindi}
            isCourseIDFetched={isCourseIDFetched}
            setNotificationData={setNotificationData}
            setPreviewAdaptFileFlag={setPreviewAdaptFileFlag}
            previewAdaptFileCreateNewCourse={previewAdaptFileCreateNewCourse}
            adaptFileState={adaptFileState}
            openInNewTab={openInNewTab}
            fileData={fileData}
            setFileData={setFileData}
            isLocale={isLocale}
            replaceFile={replaceFile}
            setSelectedLanguage={setSelectedLanguage}
          />
        ) : null}
        {/* Translation Details Drawer */}

        {/* Conformation Modal */}
        {isConfirmModalOpen && (
          <TranslationConfirmModal
            heading={'Exit Without Saving Changes'}
            subHeading={`Translation changes will not be updated. You can't undo this action`}
            handleCloseConfirmModal={handleCloseConfirmModal}
            handleConfirmChanges={handleConfirmChanges}
            Notification={Notification}
            Background={Background}
          />
        )}
        {/* Conformation Modal */}
      </div>
    </>
  );
};

// export default TranslationLanguage;

const mapStateToProps = (state, ownProps) => ({
  addedTranslationListState: state && state.uiState && state.uiState.addedTranslationListState,
});

const mapDispatchToProps = (dispatch) => ({
  setNewTranslationList: (options) => dispatch(setNewTranslationList(options)),
  setNotificationData: (options) => dispatch(setNotificationData(options)),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(TranslationLanguage));
