import React, {createRef, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styles from './CourseWizard.module.scss';
import Header from '../../shared/header';
import {
  setNotificationData,
  setCourseMode,
  setNotificationListApi,
  setDashboardNotificationCountApi,
  setErrorStateTemplate,
  setSelectedNavigationOption,
  addedTranslationListState,
  setCountryList,
} from '../../../redux/actions/uiActions';
import {
  setMainImageBackup,
  setSelectedImage_16_9_Backup,
  setSelectedImage_1_1_Backup,
  setCroppedImage_16_9,
  setCroppedImage_1_1,
  setCroppedImageData,
  setImageData,
  setThumbnailImage169,
} from '../../../redux/actions/imageCrop/imageCropAction';
import {
  setNewCourseData,
  setNewCourseWizardState,
  setNewImageData,
  setNewAdaptFile,
  setBlockNavigation,
  setNewThumbnailImageData,
  setNewThumbnailId,
} from '../../../redux/actions/newCourseActions';
import {connect} from 'react-redux';
import Notification from '../../shared/notification';
import {_apiHeaderConfigs} from '../../utilAPIHeader';
import config from '../../../config';
import {makeRequest} from '../../utils/APIsUtils/httpsUtils';
import CreateWizard from './CreateWizard';
import EditWizard from './EditWizard';
import SearchListComponent from '../../shared/searchListComponent/index';
import {ListArraySubheader, regexNameChinese, regexNameHindi} from './Constant';
import {
  audienceListForEachFun,
  fullDataCheckFunc,
  publishEditCourseArrayFun,
  EditCourseArrayFun,
} from './CourseWizardutils';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { convertEmojiToText } from '../../utils/commonUtil';
const {currentEnv} = config;

const CourseWizardComponent = ({
  // ApiData,
  //closeCourseWizard,
  mainImageBackup,
  setMainImageBackup,
  setNewThumbnailImageData,
  newCourseData,
  setNewCourseData,
  setNewImageData,
  setNewCourseWizardState,
  newCourseWizardState,
  //fetchSelectedCourse,
  setNotificationData,
  notificationData,
  setCourseMode,
  newThumbnailId,
  showCreateNewCoursesWizard,
  setBlockNavigation,
  fetchCoursesList,
  setNotificationListApi,
  setDashboardNotificationCountApi,
  setSelectedImage_16_9_Backup,
  setSelectedImage_1_1_Backup,
  setCroppedImage_16_9,
  setCroppedImage_1_1,
  setCroppedImageData,
  setImageData,
  currentUserId,
  openNewCourse,
  setNewThumbnailId,
  thumbnailImage169,
  setThumbnailImage169,
  setErrorStateTemplate,
  setSelectedNavigationOption,
  addedTranslationListState,
  allState,
  countryList,
  setCountryList,
  remoteConfig,
  allCoursesList = []
}) => {
  const [mainState, setMainState] = useState(true);
  const [nextState, setNextState] = useState(false);
  const [nextTranslation, setNextTranslation] = useState(false);
  const [nextUserGrp, setNextUserGrp] = useState(false);
  const [publishBtn, setPublishBtn] = useState(false);

  const [editState, setEditState] = useState(false);
  const [headerButtons, setHeaderButtons] = useState(false);
  const [openDecommission, setOpenDecommission] = useState(false);
  const [nextButtonDisable, setNextButtonDisable] = useState(true);
  const [publishButtonDisableEdit, setPublishButtonDisableEdit] = useState(false);
  const [saveAndExitDisable, setSaveAndExitDisable] = useState(false);
  const [btn2SaveAndExitDisable, setBtn2SaveAndExitDisable] = useState(true);
  const [exitButtonEnable, setExitButtonEnable] = useState(true);
  const [uploadingFileButton, setUploadingFileButton] = useState(false);
  const [uploadingFileButtonHindi, setUploadingFileButtonHindi] = useState(false);
  const [disableImageLib, setDisableImageLib] = useState(false);
  const [imageFileDesk, setImageFileDesk] = useState(null);
  const [data, setData] = useState(null);
  const [apiData, setApiData] = useState(null);
  const [categories, setCategories] = useState([]);
  const [audienceGroup, setAudienceGroup] = useState([]);
  const [skills, setSkills] = useState([]);
  const [keyword, setKeyword] = useState([]);
  const [pillsAddAudienceGroup, setPillsAddAudienceGroup] = useState([]);
  const [pillsDataSkills, setPillsDataSkills] = useState([]);
  const [pillsDataKeyword, setPillsDataKeyword] = useState([]);
  const [categoryData, setCategoryData] = useState('');
  const [typesCheckbox, setTypeCheckbox] = useState();
  const [fileData, setFileData] = useState(null);
  const [fileDataTemp, setFileDataTemp] = useState(null);
  const [fileDataHindi, setFileDataHindi] = useState(null);
  const [fileDataTempHindi, setFileDataTempHindi] = useState(null);

  const [newCourseID, setNewCourseID] = useState(null);
  const [isCourseIDFetched, setIsCourseIDFetched] = useState(false);
  const [addUserGroupList, setAddUserGroupList] = useState(null);
  const [addUserGroupListCopy, setAddUserGroupListCopy] = useState([]);
  const [disableStateUserManagement, setDisableStateUserManagement] = useState(false);
  const [deleteUserGroupList, setDeleteUserGroupList] = useState([]);
  const containerRef = useRef();
  const skillsRef = useRef();
  const keywordsRef = useRef();
  const router = useHistory();
  const location = useLocation();
  const {query = {}} = router.location;
  const [isArchive, setIsArchive] = useState(false);
  const [openAdaptFileModal, setOpenAdaptFileModal] = useState(false);
  const [adaptFileState, setAdaptFileState] = useState('INPROGRESS');
  const [previewData, setPreviewData] = useState(null);
  const [notificationBanerCount, setNotificationBanerCount] = useState(0);
  const [previewAdaptFileFlag, setPreviewAdaptFileFlag] = useState(false);
  const [publishButtonAfterPreview, setPublishButtonAfterPreview] = useState(true);
  const [openPreviewAdaptFile, setOpenPreviewAdaptFile] = useState(false);
  const [isAPICallInProgress, setIsAPICallInProgress] = useState(false);
  const [adaptFileButtonState, setAdaptFileButtonState] = useState(true);
  const [adaptFileButtonStateHindi, setAdaptFileButtonStateHindi] = useState(true);
  const [list, setList] = useState(ListArraySubheader);
  const [subheader, setSubheader] = useState('');
  const oktaToken = JSON.parse(localStorage.getItem('okta-token-storage'));
  const [isOnline, setOnline] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [deletedAudGrpPayloadList, setDeletedAudGrpPayloadList] = useState([]);
  const [replaceFile, setReplaceFile] = useState(false);
  const [isPrimaryCourseAdded, setPrimaryCourseAdded] = useState(false);
  const [showPrimaryNotification, setShowPrimaryNotification] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isDuplicateCourseName, setIsDuplicateCourseName] = useState(false);
  const [createCourseNotification, setCreateCourseNotification] = useState(false);
  const [isBrowserBackClick, setIsBrowserBackClick] = useState(false);
  const [missingFilesMessage, setMissingFilesDataMessage] = useState('');

  window.addEventListener('online', () => {
    setOnline(true);
    window.location.reload();
  });
  window.addEventListener('offline', () => {
    setOnline(false);
  });

  const handlePopState = async (event) => {
    setIsBrowserBackClick(true);
  };

  useEffect(() => {
    if(isBrowserBackClick) {
      handleBrowserBack();
    }
  },[isBrowserBackClick]);

  const handleBrowserBack = async () => {
    if (data?.adaptFileName?.length === 0 && adaptFileState === 'FAILURE') {
      setIsLoading(true);
      await deleteCourseIfUploadFailed(data?.courseId, data?.audienceGroups);
      setIsLoading(false);
    }
    let tempObj = JSON.parse(sessionStorage.getItem('path'));
    // Use URLSearchParams to parse the query string
    const urlParams = new URLSearchParams(tempObj?.search);
    // Extract the id value using the 'get' method
    const courseId = urlParams.get('id');
    closeCourseWizard(courseId);
  };

  useEffect(() => {
    sessionStorage.setItem('path', JSON.stringify(router?.location));
    // setMainImageBackup(null);
    setNewThumbnailId('');
    window.addEventListener('popstate', handlePopState);

    return () => {
      setShowPrimaryNotification(false);
      localStorage.removeItem('previousLanguage');
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    if(adaptFileState === 'FAILURE') {
      setBtn2SaveAndExitDisable(true);
    } else if (getEnableSaveAndExit({ primaryTitle: data?.primaryTitle, primaryDescription: data?.primaryDescription })) {
      setBtn2SaveAndExitDisable(false);
    }
  },[adaptFileState])
  useEffect(() => {
    if (uploadingFileButton) {
      setPublishButtonDisableEdit(true);
      setSaveAndExitDisable(true);
      setDisableStateUserManagement(true);
    } else if(data?.adaptFileName?.length === 0) {
      setExitButtonEnable(true);
      setNextButtonDisable(true);
    }
    else {
      setPublishButtonDisableEdit(false);
      setSaveAndExitDisable(false);
      setDisableStateUserManagement(false);
      getEnableSaveAndExit({
        primaryTitle: data?.primaryTitle,
        primaryDescription: data?.primaryDescription,
      }) &&
        !editState &&
        setExitButtonEnable(false);
    }
  }, [uploadingFileButton]);
  useEffect(() => {
    if (uploadingFileButtonHindi) {
      setPublishButtonDisableEdit(true);
      setSaveAndExitDisable(true);
      setDisableStateUserManagement(true);
    } else {
      setPublishButtonDisableEdit(false);
      setSaveAndExitDisable(false);
      setDisableStateUserManagement(false);
      getEnableSaveAndExit({
        primaryTitle: data?.primaryTitle,
        chineseTitle: data?.chineseTitle,
        primaryDescription: data?.primaryDescription,
        chineseDescription: data?.chineseDescription,
      }) &&
        !editState &&
        setExitButtonEnable(false);
    }
  }, [uploadingFileButtonHindi]);

  useEffect(() => {
    setPublishButtonDisableEdit(false);
    setNextButtonDisable(false);
    if (allCheckFunc()) {
      setNextButtonDisable(false);
    }
  }, [publishButtonAfterPreview]);

  const handleCountrySelection = (countryListState) => {
    setIsEdited(true);
    editState || newThumbnailId?.length > 0 ? editSaveAndExitEnable(data, countryListState) : null;
  };

  const validateCourseName = (value) => {
    const query = new URLSearchParams(location.search);
    const urlID = query.get('id');
    
    const courses = allCoursesList?.filter(c => c?.title?.toLowerCase()?.trim() === value?.toLowerCase()?.trim() && c?.courseId !== urlID && c?.courseState !== 'Archive');

    if (courses?.length > 0) {
      setIsDuplicateCourseName(true);
    } else {
      setIsDuplicateCourseName(false);
    }
  }

  const deleteCourseIfUploadFailed = async(selectedCourseId) => {
    await makeRequest({
      method: 'DELETE',
      endpoint: `/admin/api/v1/courses/${selectedCourseId}`,
    });
    return;
  }

  const handleDataFill = (name, value) => {
    value = convertEmojiToText(value)
    if (name === 'duration') {
      let limit = 25000;
      if (value > limit) {
        value = limit;
      }
    }

    if(name === 'primaryTitle') {
      validateCourseName(value);
    }

    setIsEdited(true);
    let updatedDetails = {
      ...data,
      [name]: value,
    };
    setData(updatedDetails);
    editState || newThumbnailId?.length > 0 ? editSaveAndExitEnable(updatedDetails) : null;
  };

  const handleDataFillMultiLingual = (name, value, eleData) => {
    if (name === 'duration') {
      let limit = 25000;
      if (value > limit) {
        value = limit;
      }
    }
    let tempObj =
      eleData?.multilingualDetails && eleData?.multilingualDetails[0]
        ? eleData?.multilingualDetails[0]
        : {};
    tempObj[name] = value;

    setData((prevData) => ({
      ...prevData,
      multilingualDetails: [tempObj],
    }));
    editState || newThumbnailId?.length > 0 ? editSaveAndExitEnable(data) : null;
    if (
      getEnableSaveAndExit({
        primaryTitle: data?.primaryTitle,
        primaryDescription: data?.primaryDescription,
      })
    ) {
      setBtn2SaveAndExitDisable(false);
    }
  };
  const fetchSelectedCourse = async (courseId) => {
    if (
      courseId != 0 &&
      courseId != '0' &&
      courseId != null &&
      courseId != 'null' &&
      courseId != undefined &&
      courseId != 'undefined' &&
      courseId != '0&sub_header'
    ) {
      const dataCourseId = await makeRequest({
        method: 'GET',
        endpoint: `/admin/api/v1/courses/${courseId}`,
      });
      if (dataCourseId != 'ERR_NETWORK' && dataCourseId) {
        let respData = {};

        if (dataCourseId?.data) {
          respData = {
            ...dataCourseId?.data,
            course_state: dataCourseId?.data?.courseState,
            courseState: dataCourseId?.data?.courseState,
          };

          setApiData(respData);
          if (dataCourseId?.data?.adaptFileName?.length > 0) {
            setReplaceFile(true);
          }
        }
      } else if (dataCourseId == 'ERR_NETWORK' && isOnline) {
        setErrorStateTemplate({
          variant: 'serverError',
          status: true,
        });
        setBlockNavigation(false);
      }
    }
  };
  const updateCourseDetails = async (
    id,
    courseDetails,
    publishCourse = false,
    courseBanner = true,
    newCourseIDData = null,
  ) => {
    const configData = JSON.parse(localStorage.getItem('configData'));
    let translationList = addedTranslationListState.filter(
      (ele) => ele?.locale !== configData?.locale,
    );
    courseDetails = {
      ...courseDetails,
      multilingualDetails: translationList,
    };
    setNewThumbnailId('');
    const courseTitle = newCourseID
      ? newCourseID.title
      : newCourseIDData
      ? newCourseIDData.title
      : null;
    const courseID = newCourseID
      ? newCourseID.courseId
      : newCourseIDData
      ? newCourseIDData.courseId
      : null;
    courseDetails.title = courseTitle;
    // if (fileData && fileData.name) {
    //   courseDetails.adaptFileName = fileData && fileData.name;
    // }

    //add all the UserGroups with CourseAccess and countries selected (from redux )
    let updatedAddAudGrpIds = courseDetails?.audienceGroups?.addAudGrpIds?.map((audGrp) => {
      return {
        audGrpName: audGrp?.audGrpId,
        courseType: audGrp?.courseType,
        countries: countryList.filter((country) => country.checked).map((item) => item.countryCode),
      };
    });

    //add all the UserGroups without CourseAccess (added on the UGM screen and not the previously discarded ones)
    let currentDeleteAudGrp = deletedAudGrpPayloadList?.map((audGrp) => {
      return {
        audGrpName: audGrp?.id,
        courseType: audGrp?.courseType,
        countries: apiData?.countryCodes,
      };
    });

    //add all the UserGroups with CourseAccess in the deletedAudGrp for the ones which countries were previously checked but in the UGM screen they have been deselected.
    let updatedDeleteAudGrpIds = [];
    if (countryList.some((item) => !item.checked)) {
      updatedDeleteAudGrpIds = courseDetails?.audienceGroups?.addAudGrpIds?.map((audGrp) => {
        return {
          audGrpName: audGrp?.audGrpId,
          courseType: apiData?.audienceGroups?.find((element) => element.title === audGrp?.audGrpId)
            ?.courseType
            ? apiData?.audienceGroups?.find((element) => element.title === audGrp?.audGrpId)
                ?.courseType
            : audGrp?.courseType,
          countries: countryList
            .filter((country) => !country.checked)
            .map((item) => item.countryCode),
        };
      });
    }

    updatedDeleteAudGrpIds = [...updatedDeleteAudGrpIds, ...currentDeleteAudGrp];

    delete courseDetails.audienceGroups.deleteAudGrpIds;
    delete courseDetails.audienceGroups.addAudGrpIds;
    delete courseDetails.countryCodes;

    courseDetails.audienceGroups = {
      ...courseDetails?.audienceGroups,
      addAudGrps: updatedAddAudGrpIds,
      deleteAudGrps: updatedDeleteAudGrpIds,
    };

    // Keep it for checking the payload
    // console.log('🚀 ~ courseDetails:', courseDetails);

    const dataCourseID = await makeRequest({
      method: 'PUT',
      endpoint: `/admin/api/v1/courses/${courseID}`,
      body: JSON.stringify(courseDetails),
    });
    if (dataCourseID) {
      //fetchSelectedCourse(dataCourseID?.data?.entry?.uid);
      // After BFF changes are Deployed
      // Remove above fetchSelectedCourse call and Use Below fetchSelectedCourse
      setTimeout(() => {
        if (dataCourseID?.data?.entry?.uid) {
          //fetchSelectedCourse(dataCourseID?.data?.entry?.uid);
          // fetchCoursesList();
        } else if (dataCourseID?.data?.courseId) {
          //fetchSelectedCourse(dataCourseID?.data?.courseId);
          // fetchCoursesList();
        }
      }, 1000);
      if (dataCourseID.status === 'Success' && publishCourse && courseBanner) {
        setNotificationData({
          show: true,
          type: 'SUCCESS',
          title: 'New Course Launched',
          description: `${courseDetails.primaryTitle} has been launched by the ${oktaToken.idToken.claims.fullName}`,
        });
      } else if (
        dataCourseID.status === 'Success' &&
        (courseDetails.courseState == 'Live' || courseDetails.courseState == 'Offline') &&
        courseBanner
      ) {
        setNotificationData({
          show: true,
          type: 'SUCCESS',
          title: 'Course Edited',
          description: `${courseDetails.primaryTitle} has been edited by the ${oktaToken.idToken.claims.fullName}`,
        });
      }
    }

    setNotificationListApi();
    setDashboardNotificationCountApi(currentUserId);
  };
  const createAudienceLists = (list) => {
    let allList = [];
    list?.forEach((element) => {
      let tempObj = {
        title: element?.audienceGrpName,
        id: element?.audienceGrpName,
        audienceGrpDescription: element?.audienceGrpDescription || '',
      };
      allList.push(tempObj);
    });
    //setDefaultAudienceGroup(defaultList);
    setAudienceGroup(allList);
  };

  const fetchAudienceGroupList = async () => {
    const configData = JSON.parse(localStorage.getItem('configData'));
    let countryCodeString = '';
    countryList.forEach((element) => {
      countryCodeString += element.checked ? `&countryCodes=${element.countryCode}` : '';
    });

    const dataAudience = await makeRequest({
      method: 'GET',
      endpoint: `/admin/api/v1/audience-groups?sortBy=oldest_to_newest${
        remoteConfig?.user_group_country_targeting
          ? countryCodeString?.length > 0
            ? countryCodeString
            : ''
          : `&countryCodes=${configData?.code?.toUpperCase()}`
      }`,
    });
    if (dataAudience != 'ERR_NETWORK' && dataAudience) {
      createAudienceLists(dataAudience?.data);
      setErrorStateTemplate({
        variant: '',
        status: false,
      });
    } else if (dataAudience == 'ERR_NETWORK' && isOnline) {
      setErrorStateTemplate({
        variant: 'serverError',
        status: true,
      });
      setBlockNavigation(false);
    }
  };
  const fetchCategories = async () => {
    const dataCategories = await makeRequest({
      method: 'GET',
      endpoint: '/api/v1/categories',
    });
    if (dataCategories) {
      setCategories(dataCategories?.data);
    }
  };

  const fetchSkills = async () => {
    const dataSkills = await makeRequest({
      method: 'GET',
      endpoint: '/api/v1/skills',
    });
    if (dataSkills && dataSkills?.data && dataSkills?.data?.length > 0) {
      if (dataSkills && dataSkills?.data && dataSkills?.data?.length > 0) {
        setSkills(dataSkills?.data);
      }
    }
  };

  const fetchKeywords = async () => {
    const dataKeywords = await makeRequest({
      method: 'GET',
      endpoint: '/api/v1/keywords',
    });
    if (dataKeywords) {
      setKeyword(dataKeywords?.data);
    }
  };

  const checkFun = async (eleData, publishState = false) => {
    if (eleData && eleData?.primaryTitle?.length > 2 && !exitButtonEnable) {
      const courseId = newCourseID && newCourseID.courseId;
      let elementData = EditCourseArrayFun(
        publishState,
        eleData,
        addUserGroupList,
        deleteUserGroupList,
        fileData,
      );
      await updateCourseDetails(courseId, elementData, publishState, true, null);
    }
  };

  const saveAndExitClick = async () => {
    if (data?.courseId === null || data?.courseId === undefined) {
      await callCreateCourseAPI();
    } else {
      await checkFun(data, false);
    }
  };
  const allCheckFunc = () => {
    return fullDataCheckFunc(data, fileData, publishButtonAfterPreview);
  };

  const getEnableSaveAndExit = ({primaryTitle = '', primaryDescription = ''}) => {
    let flag = false;

    // if (primaryTitle?.length > 0 && primaryDescription?.length > 0) {
    //   if (
    //     primaryTitle?.length >= 1 &&
    //     primaryDescription?.length >= 1 &&
    //     data?.thumbnailId?.length > 0
    //   ) {
    //     flag = true;
    //   }
    // }
    if (primaryTitle?.length > 0 && primaryDescription?.length > 0 && !isDuplicateCourseName) {
      if (
        primaryTitle?.length >= 1 &&
        primaryDescription?.length >= 1 &&
        data?.thumbnailId?.length > 0
      ) {
        flag = true;
      }
    }
    return flag;
  };
  const closeCourseWizard = (courseId) => {
    //const newWindow = window.open(url + '?id=' + id, '_blank', 'noopener,noreferrer');
    setTimeout(() => {
      if((data?.adaptFileName?.length === 0 && adaptFileState === 'FAILURE')) {
        router.push({
          pathname: '/Coursesmanagement',
          search: `?id=0`,
        });
      } else {
        router.push({
          pathname: '/Coursesmanagement',
          search: `?id=${courseId ? courseId : data?.courseId ? data?.courseId : '0'}`,
          //query: {actionName: ""},
        });
      }
      setNewCourseWizardState({
        courseWizardOpen: false,
        courseWizardStateOpen: '',
        section: '',
      });
      setNewCourseData(null);
      setNewThumbnailImageData(null);
    }, 500);
  };
  React.useEffect(() => {
    const unblock = router.block((location, action) => {
      if (newCourseWizardState.courseWizardOpen) {
        //closeCourseWizard();
        if (newCourseWizardState.section.length > 0) {
          return true;
        } else if (newCourseWizardState.section.length <= 0) {
          if (action === 'PUSH') {
            return true;
          }
          return false;
        }
      }
      return true;
    });

    return () => {
      unblock();
      sessionStorage.setItem('newThumbnailId', '');
    };
  }, []);

  const previousLanguage = JSON.parse(localStorage.getItem('previousLanguage'));
  const translationListCount = localStorage.getItem('translationListCount');
  useEffect(async () => {
    if (
      // getEnableSaveAndExit({
      //   primaryTitle: data?.primaryTitle,
      //   primaryDescription: data?.primaryDescription,
      // }) &&
      // data?.category?.length > 0 &&
      data?.primaryTitle?.length > 0 &&
      data?.primaryTitle?.length <= 100 &&
      data?.primaryDescription?.length > 0 &&
      data?.primaryDescription?.length <= 500 &&
      data?.primarySpeaker?.length <= 30 &&
      data?.adaptFileName?.length > 0 &&
      data?.thumbnailId?.length > 0 &&
      !isDuplicateCourseName &&
      data?.duration > 0 &&
      !nextState &&
      data?.adaptFileName
    ) {
      setHeaderButtons(true);
      setPublishButtonDisableEdit(false);
      !editState && setExitButtonEnable(false);
      if (data?.adaptFileName?.length > 0 && data?.duration > 0) {
        setNextButtonDisable(false);
      }
      setAdaptFileButtonState(false);
      data &&
        data?.multilingualDetails !== undefined &&
        data?.multilingualDetails[0] !== null &&
        data?.multilingualDetails[0]?.title &&
        data?.multilingualDetails[0]?.description &&
        data?.multilingualDetails[0]?.title?.length > 0 &&
        data?.multilingualDetails[0]?.description?.length > 0 &&
        setAdaptFileButtonStateHindi(false);
    } else {
      setNextButtonDisable(true);
      // setNextState(true);
      // setNextState(true);
      setPublishButtonDisableEdit(true);
      setAdaptFileButtonState(true);
      setAdaptFileButtonStateHindi(true);
      !editState && !nextState && setExitButtonEnable(true);
      //setBlockNavigation(false);
      !nextState && setHeaderButtons(false);
    }
    if (allCheckFunc() && nextState) {
      setNextButtonDisable(false);
      !editState && setExitButtonEnable(false);
      setPublishButtonDisableEdit(false);
    } else {
      !allCheckFunc() && nextState && setNextButtonDisable(true);
    }
  }, [data]);

  const previousDeletedLanguage = JSON.parse(localStorage.getItem('previousDeletedLanguage'));
  // Note: Not Required for now. keep it for refrence
  // useEffect(() => {
  //   let prevThumbnailID = sessionStorage?.getItem('newThumbnailId');

  //   if (
  //     (addedTranslationListState?.length - 1) !== data?.multilingualDetails?.length ||
  //     (data?.multilingualDetails?.length === 0 && addedTranslationListState?.length - 1 >= 1)
  //   ) {
  //     setBtn2SaveAndExitDisable(false);
  //   } else {
  //     !prevThumbnailID && setBtn2SaveAndExitDisable(true);
  //   }
  //   if (previousLanguage?.title?.length > 0) {
  //     addedTranslationListState.map((item) => {
  //       if (item?.locale === previousLanguage?.locale) {
  //         if (
  //           item?.title !== previousLanguage?.title ||
  //           item?.description !== previousLanguage?.description ||
  //           item?.speaker !== previousLanguage?.speaker ||
  //           item?.adaptFileName !== previousLanguage?.adaptFileName ||
  //           item?.version !== previousLanguage?.version
  //         ) {
  //           setBtn2SaveAndExitDisable(false);
  //         }
  //       }
  //     });
  //   }
  // }, [previousLanguage, addedTranslationListState, translationListCount]);

  useEffect(() => {
    let prevThumbnailID = sessionStorage?.getItem('newThumbnailId');

    if (newThumbnailId || prevThumbnailID) {
      setBtn2SaveAndExitDisable(false);
      newThumbnailId && sessionStorage.setItem('newThumbnailId', newThumbnailId);
    }
  }, [newThumbnailId]);

  useEffect(async () => {
    //handleUserTargetingGroupsList()
    const initialize = async () => {
      await fetchAudienceGroupList();
    };
    initialize();
    // setBlockNavigation(true);
    // fetch categories
    fetchCategories();
    // fetch skills
    fetchSkills();
    // fetchCountriesList();
    // fetch keyword
    fetchKeywords();
    if (router.location.pathname == '/create-course-wizard') {
      setSelectedNavigationOption('COURSES-MANAGEMENT');
      if (router.location.search) {
        let string = router.location.search;
        const stringArray = string?.split('&');
        let strArr1 = stringArray[0]?.split('=');
        let strArr2 = stringArray[1]?.split('=');
        let selectedID = strArr1[1];
        if (
          selectedID == 0 ||
          selectedID == '0' ||
          selectedID == 1 ||
          selectedID == '1' ||
          selectedID == undefined ||
          selectedID == 'undefined' ||
          (selectedID == null && selectedID == 'null')
        ) {
          !newCourseData && setData(null);
          setIsLoading(false);
        }
        let selectedSubHeader = strArr2[1];
        if (selectedSubHeader == 'course_description') {
          setMainState(true);
          setNextState(false);
          setNextTranslation(false);
          setNextUserGrp(false);
          setNextTranslation(false);
          setNextUserGrp(false);
          setPublishBtn(false);
          setEditState(false);
          setSubheader('Course Information');
        } else if (selectedSubHeader == 'translation') {
          setMainState(false);
          setNextState(false);
          setNextTranslation(true);
          setNextUserGrp(false);
          setPublishBtn(false);
          setEditState(false);
          setSubheader('Translation');
        } else if (selectedSubHeader == 'user_group_targeting') {
          setMainState(false);
          setNextState(false);
          setNextTranslation(false);
          setNextUserGrp(true);
          setPublishBtn(false);
          setEditState(false);
          setSubheader('User Group Targeting');
        } else if (selectedSubHeader == 'categories_skills_tags') {
          setMainState(false);
          setNextState(true);
          setNextTranslation(false);
          setNextUserGrp(false);
          setNextTranslation(false);
          setNextUserGrp(false);
          setPublishBtn(true);
          setEditState(false);
          setSubheader('Categories, Skills, Tags');
        } else if (selectedSubHeader == 'edit') {
          setMainState(false);
          setNextState(false);
          setNextTranslation(false);
          setNextUserGrp(false);
          setNextTranslation(false);
          setNextUserGrp(false);
          setEditState(true);
        }
        selectedID != 0 &&
          selectedID != '0' &&
          selectedID != 1 &&
          selectedID != '1' &&
          selectedID != undefined &&
          selectedID != 'undefined' &&
          selectedID != null &&
          selectedID != 'null' &&
          !newCourseData &&
          (await fetchSelectedCourse(selectedID));
      }
    }
    //fetchAudienceGroupList

    return () => {
      setData(null);
      setPillsDataKeyword(null);
      setPillsDataSkills(null);
      setOpenAdaptFileModal(false);
      setBlockNavigation(false);
    };
  }, []);
  useEffect(async () => {
    // setPublishButtonDisableEdit(true);
    let audienceGrp = [];
    if (apiData && !openNewCourse) {
      setNewCourseData(null);
      setData((prevData) => ({
        ...prevData,
        ...apiData,
        types: apiData?.types?.length > 0 ? apiData?.types : undefined,
      }));
      validateCourseName(apiData?.primaryTitle)
      setCategoryData(apiData?.category?.length > 0 ? apiData?.category[0]?.title : '');
      apiData?.courseState === 'Live' && setDisableStateUserManagement(true);
      apiData?.courseState === 'Offline' && setDisableStateUserManagement(false);
      // apiData?.audienceGroups.forEach((data) => {
      //   let tempObj = {
      //     id: data?.audienceGrpName,
      //     title: data?.audienceGrpName,
      //     audienceGrpDescription: data?.audienceGrpDescription,
      //     courseType: data?.courseType || 'General visibility',
      //   };
      //   audienceGrp.push(tempObj);
      // });
      audienceGrp = apiData?.audienceGroups?.map((item) => {
        return {
          ...item,
          id: item?.title || item?.audGrpName,
          title: item?.title || item?.audGrpName,
          audienceGrpDescription: item?.audienceGrpDescription || item?.description,
          courseType: item?.courseType || 'General visibility',
        };
      });
      setAddUserGroupList(audienceGrp);
      setAddUserGroupListCopy(new Array(...audienceGrp));
      setIsCourseIDFetched(true);
      let temp = {
        courseId: apiData.courseId,
        courseState: apiData.courseState,
        title: apiData.title, //TODO Backend is not sending UUID in response
      };
      setPillsAddAudienceGroup(audienceGrp);
      setNewCourseID(temp);
      setPillsDataKeyword(apiData?.keywords);
      setPillsDataSkills(apiData?.skills);
      setMainImageBackup(apiData?.thumbnail);
      setTypeCheckbox(apiData?.types?.toString());
      if (
        newCourseWizardState.courseWizardOpen &&
        newCourseWizardState.courseWizardStateOpen === 'edit'
      ) {
        setEditState(true);
        setMainState(false);
        setNextState(false);
        setNextTranslation(false);
        setNextUserGrp(false);
        setPublishBtn(false);
        setNextTranslation(false);
        setNextUserGrp(false);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [apiData]);

  const handleClickOutsideContainer = (event) => {
    if (
      //showCreateNewCoursesWizard &&
      (data?.primaryTitle?.length >= 3 ||
        data?.chineseTitle?.length >= 3 ||
        newCourseID?.courseId) &&
      containerRef &&
      !uploadingFileButton &&
      !uploadingFileButtonHindi &&
      (newCourseWizardState.courseWizardStateOpen === 'main' ||
        newCourseWizardState.courseWizardStateOpen === 'edit') &&
      !containerRef.current?.contains(event.target) &&
      event.target.className.includes('SideNavigation') &&
      !(
        event.target.className.indexOf('CourseWizard') !== -1 ||
        event.target.className.indexOf('Header') !== -1
      )
    ) {
      event.preventDefault();
      setBlockNavigation(true);
      setNotificationData({
        show: true,
        type: 'FAILURE',
        title: 'Course Not Saved',
        description: 'Save & Exit to save this course in a Draft state.',
      });
    }
  };

  const handleClickOutsideContainerUploading = (event) => {
    if (
      // showCreateNewCoursesWizard &&
      uploadingFileButton &&
      uploadingFileButtonHindi &&
      containerRef &&
      (newCourseWizardState.courseWizardStateOpen === 'main' ||
        newCourseWizardState.courseWizardStateOpen === 'edit') &&
      !containerRef.current?.contains(event.target) &&
      event.target.className.includes('SideNavigation') &&
      !(
        event.target.className.indexOf('CourseWizard') !== -1 ||
        event.target.className.indexOf('Header') !== -1
      )
    ) {
      event.preventDefault();
      setBlockNavigation(true);
      setNotificationData({
        show: true,
        type: 'FAILURE',
        title: 'No Action can be done during File Uploading',
        description:
          'All the buttons are disabled when the file is uploading. Please wait for the file to upload to do any action.',
      });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideContainer, false);
    if (
      getEnableSaveAndExit({
        primaryTitle: data?.primaryTitle,
        primaryDescription: data?.primaryDescription,
      }) &&
      data?.duration &&
      !nextState &&
      !uploadingFileButton &&
      !uploadingFileButtonHindi
    ) {
      setAdaptFileButtonState(false);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideContainer, false);
      document.removeEventListener('click', handleClickOutsideContainer, false);
    };
  }, [data, uploadingFileButton, uploadingFileButtonHindi]);
  useEffect(() => {
    (uploadingFileButton || uploadingFileButtonHindi) &&
      document.addEventListener('mousedown', handleClickOutsideContainerUploading, false) &&
      setOpenDecommission(false);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideContainerUploading, false);
      document.removeEventListener('click', handleClickOutsideContainerUploading, false);
      //setBlockNavigation(false);
    };
  }, [uploadingFileButton, uploadingFileButtonHindi]);

  const getCourseButtonStatus = () => {
    const status = data?.courseState?.toString()?.toLowerCase();
    return status === 'live' ? 'Go Offline' : status === 'offline' ? 'Go Live' : 'Delete Course';
  };

  const editSaveAndExitEnable = (data, newCountryList = []) => {
    setIsEdited(true);
    const {primaryTitle = '', primaryDescription = ''} = data || {};
    const newPrimaryTitle = newCourseData?.data?.primaryTitle || '';
    const newPrimaryDescription = newCourseData?.data?.primaryDescription || '';

    const hasValidTitleAndDescription =
      (primaryTitle.length > 0 && primaryDescription.length > 0) ||
      (newPrimaryTitle.length > 0 && newPrimaryDescription.length > 0) && !isDuplicateCourseName;

    const hasCheckedCountry =
      newCountryList.length > 0
        ? newCountryList.some((ele) => ele.checked)
        : countryList.some((ele) => ele.checked);

    const isDisabled = !(hasValidTitleAndDescription && hasCheckedCountry);

    setBtn2SaveAndExitDisable(isDisabled);
  };

  const filterListById = (id, list, setlist, name, stateFunction) => {
    let results = list?.filter((element) => {
      return element?.id !== id;
    });
    setlist(results);
    let tempArr = [];
    results?.forEach((data) => {
      tempArr.push(data.id);
    });
    stateFunction(name, tempArr);
  };

  function setCookie(cookieKey, value, expirationTimeInMinute, domain = '.amway.com', path = '/') {
    try {
      let expires = '';
      if (expirationTimeInMinute) {
        const date = new Date();
        date.setTime(date.getTime() + expirationTimeInMinute * 60000);
        expires = `; expires=${date.toGMTString()}`;
      }

      let _cookieString = '';
      if (cookieKey && value) {
        _cookieString = `${cookieKey}=${value}`;
      } else if (expires) {
        _cookieString += `${expires}=${value}`;
      }

      if (window.location.hostname === 'localhost') {
        document.cookie = `${_cookieString}; domain=localhost; path=${path}`;
      } else {
        document.cookie = `${_cookieString}; domain=${domain}; path=${path}; Secure`;
      }
    } catch (err) {
      //catch
      console.log(err);
    }
  }
  const openInNewTab = (url) => {
    if (previewData !== null && previewData?.data?.version) {
      const newWindow = window.open(previewData?.data?.courseUrl, '_blank', 'noopener,noreferrer');
      const _cookies = previewData?.data?.cookies;
      const _lrsCookies = previewData?.data?.lrsCookies;
      if (_cookies) {
        for (const item of _cookies) {
          setCookie(
            item?.cookieName,
            item?.cookieValue,
            item?.cookieExpirationInMinutes,
            previewData?.data?.domain,
            previewData?.data?.coursePath,
          );
        }
      }
      if (_lrsCookies) {
        for (const item of _lrsCookies) {
          setCookie(
            item?.cookieName,
            item?.cookieValue,
            item?.cookieExpirationInMinutes,
            previewData?.data?.domain,
            previewData?.data?.dataPath,
          );
        }
      }

      setPublishButtonAfterPreview(false);
      allCheckFunc();
      if (newWindow) {
        newWindow.opener = null;
      }
    }
    setOpenPreviewAdaptFile(false);
  };
  const publishEditCourseDetails = async (
    publishCourse = false,
    courseBanner = true,
    locale = '',
    fileData = null,
    failedAdaptFile = true,
    mainData,
    addedUserGroupList,
    deletedUserGroupList,
    newCourseIDData,
  ) => {
    let tempArr = [];
    let file = null;
    file = failedAdaptFile ? null : fileData;
    tempArr = publishEditCourseArrayFun(
      publishCourse,
      mainData,
      addedUserGroupList,
      deletedUserGroupList,
      file,
      locale,
      newCourseIDData,
    );
    await updateCourseDetails(
      data?.id || data?.courseId,
      tempArr,
      publishCourse,
      courseBanner,
      newCourseIDData,
    );

    // ABOACA-12170: commenting this code to fix this ticket
    // if(!failedAdaptFile) {
    //   setTimeout(() => {
    //     setMainImageBackup(null);
    //     setNewCourseData(null);
    //   }, 1502);
    // }
  };

  const previewAdaptFileApi = async (
    versionNumber,
    id,
    locale,
    file,
    tempData = null,
    newCourseIDData = null,
  ) => {
    const configData = JSON.parse(localStorage.getItem('configData'));
    //let versionNum = versionNumber ? versionNumber : data?.version;
    let versionNum = 0;
    if (locale == configData?.locale) {
      versionNum = versionNumber ? versionNumber : data.version ? Number(data.version) : 0;
    }
    const courseId = data?.courseId || id;

    const apiData = await makeRequest({
      method: 'GET',
      endpoint: `/admin/api/v1/courses/${courseId}/preview?version=${
        versionNum ? versionNum : '0'
      }&locale=${configData?.locale}`,
    });
    if (apiData?.data) {
      if (
        apiData?.status?.toLowerCase() === 'success' &&
        !apiData?.data?.isErrorOccuredInHosting &&
        apiData?.data?.version >= versionNum
      ) {
        setPreviewData(apiData);
        setAdaptFileState('SUCCESS');
        setPrimaryCourseAdded(true);
        setShowPrimaryNotification(true);
        if (allCheckFunc() && mainState) {
          setNextButtonDisable(false);
        } else if (allCheckFunc() && nextState) {
          setNextButtonDisable(false);
        }
        if (locale == configData?.locale) {
          setData((prevData) => ({
            ...prevData,
            adaptFileName: file?.name,
          }));
          tempData.version = versionNum;
          tempData.adaptFileName = file?.name;
          handleDataFill('version', versionNum);
          // handleDataFill('adaptFileName', file?.name);
        }
        setPublishButtonDisableEdit(false);
        if (apiData?.data?.version > versionNum) {
          if (locale == configData?.locale) {
            versionNum = apiData?.data?.version;
            setData((prevData) => ({
              ...prevData,
              adaptFileName: file?.name,
            }));
            handleDataFill('version', apiData?.data?.version);
            setFileDataTemp(file);
          }
        }

        await publishEditCourseDetails(
          false,
          false,
          locale,
          file,
          false,
          tempData,
          addUserGroupList,
          deleteUserGroupList,
          newCourseIDData,
        );
        await fetchSelectedCourse(newCourseIDData.courseId);
        if (locale == configData?.locale) {
          setNotificationData({
            show: true,
            type: 'SUCCESS',
            title: `Course File ${replaceFile ? 'Replaced' : 'Uploaded'} Successfully`,
            description: `${file?.name} has been uploaded.`,
          });
        }
      } else if (
        !apiData?.isErrorOccuredInHosting &&
        apiData?.data?.version != versionNum &&
        apiData?.data?.version < versionNum
      ) {
        handleDataFill('version', versionNum);
        if (uploadingFileButton) {
          setNextButtonDisable(true);
        }
        await previewAdaptFileApi(versionNum, courseId, locale, file, tempData, newCourseIDData);
      } else if (
        apiData?.status?.toLowerCase() === 'success' &&
        apiData?.data?.isErrorOccuredInHosting &&
        apiData?.data?.version >= versionNum
      ) {
        setAdaptFileState('FAILURE');
        // setNotificationData({
        //   show: true,
        //   type: 'WARNING',
        //   title: 'File Hosting Failed',
        //   description:
        //     'Course File hosting failed. Please re-uplaod the Course File to publish this course. Click on Course Information to access the upload button.',
        // });
        setMissingFilesDataMessage(apiData?.data?.missingFiles);
        if (apiData?.data?.version > versionNum) {
          if (locale == configData?.locale) {
            versionNum = apiData?.data?.version;
            setData((prevData) => ({
              ...prevData,
              version: apiData?.data?.version,
            }));
            tempData.version = versionNum;
          }
        }
        await publishEditCourseDetails(
          false,
          false,
          locale,
          file,
          true,
          tempData,
          addUserGroupList,
          deleteUserGroupList,
          newCourseIDData,
        );
        await fetchSelectedCourse(newCourseIDData.courseId);
      }
    }
    return;
  };

  const previewAdaptFileApiNew = async (versionNumber, locale) => {
    const configData = JSON.parse(localStorage.getItem('configData'));
    //const versionNum = versionNumber ? versionNumber : data?.version;
    let versionNum = 0;
    if (locale == configData?.locale) {
      versionNum = versionNumber ? versionNumber : data.version ? Number(data.version) : 0;
    }
    const courseId = data?.courseId;

    const apiData = await makeRequest({
      method: 'GET',
      endpoint: `/admin/api/v1/courses/${courseId}/preview?version=${
        versionNum ? versionNum : '0'
      }&locale=${configData?.locale}`,
    });
    if (apiData) {
      if (
        apiData?.status?.toLowerCase() === 'success' &&
        !apiData?.data?.isErrorOccuredInHosting &&
        apiData?.data?.version >= versionNum
      ) {
        setPreviewData(apiData);
        setIsAPICallInProgress(false);
        setNotificationBanerCount(1);
        setAdaptFileState('SUCCESS');
        setPublishButtonDisableEdit(false);
        setPreviewAdaptFileFlag(false);
        nextState && setPublishButtonAfterPreview(false);
        if (allCheckFunc() && mainState) {
          setNextButtonDisable(false);
        } else if (allCheckFunc() && nextState) {
          setNextButtonDisable(false);
        }
        if (locale == configData?.locale) {
          setNotificationData({
            show: true,
            type: 'SUCCESS',
            title: `Course File ${versionNum == 0 ? 'Uploaded' : 'Replaced'} Successfully`,
            description: `${fileData?.name} has been uploaded.`,
          });
        }
      } else if (!apiData?.isErrorOccuredInHosting && apiData?.version < versionNum) {
        if (apiData?.version == 0) {
          setIsAPICallInProgress(true);
          setPreviewData(null);
          previewAdaptFileApiNew(versionNum);
          setNotificationBanerCount(Number(notificationBanerCount) + 1);
        } else {
          setPreviewData(apiData);
          setNotificationData({
            show: true,
            type: 'FAILURE',
            title: 'Course File hosting is In-Progress',
            description: `${fileData?.name} is being hosted. Currently you are pre-viewing the previously uploaded Course File. Please wait for a few seconds for pre-viewing the recently uploaded Course file.`,
          });
        }
      } else if (apiData?.isErrorOccuredInHosting) {
        setPreviewAdaptFileFlag(false);
        setIsAPICallInProgress(false);
        if (apiData?.version == 0) {
          setNotificationData({
            show: true,
            type: 'WARNING',
            title: 'File Hosting Failed',
            description:
              'Course file hosting failed. Please re-uplaod the course file to publish this course. Click on Course Information to access the upload button.',
          });
        } else {
          setNotificationData({
            show: true,
            type: 'WARNING',
            title: 'File Hosting Failed',
            description:
              'Course file hosting failed. Please re-upload the course file to publish this course. Click on Course Information to access the upload button. Currently you are pre-viewing previously uploaded Course file.',
          });
        }
      } else {
        return;
      }
    }
  };

  const previewAdaptFileCreateNewCourse = async (locale, versionNumber) => {
    const configData = JSON.parse(localStorage.getItem('configData'));
    //setUploadingFileButton(true);
    setHeaderButtons(false);
    let versionNum = 0;
    if (locale == configData?.locale) {
      versionNum = versionNumber ? versionNumber : data.version ? Number(data.version) : 0;
    }
    const courseId = data?.courseId;
    const url = `/admin/api/v1/courses/${courseId}/preview?version=${
      versionNum ? versionNum : '0'
    }&locale=${configData?.locale}`;
    let apiData = await makeRequest({
      method: 'GET',
      endpoint: url,
    });
    if (apiData) {
      if (apiData?.status?.toLowerCase() === 'success') {
        if (!apiData?.data?.isErrorOccuredInHosting && apiData?.data?.version >= versionNum) {
          setPreviewData(apiData);
          setIsAPICallInProgress(false);
          setOpenPreviewAdaptFile(true);
          setNotificationBanerCount(1);
          setAdaptFileState('SUCCESS');
          setPublishButtonDisableEdit(false);
          setPreviewAdaptFileFlag(false);
          nextState && setPublishButtonAfterPreview(false);
          if (allCheckFunc() && mainState) {
            setNextButtonDisable(false);
          } else if (allCheckFunc() && nextState) {
            setNextButtonDisable(false);
          }
          setUploadingFileButton(false);
          setHeaderButtons(true);
        } else if (
          !apiData?.data?.isErrorOccuredInHosting &&
          apiData?.data?.version == 0
          //          apiData?.status?.toLowerCase() === "success" &&
          // !apiData?.data?.isErrorOccuredInHosting &&
          // apiData?.data?.version < versionNum
        ) {
          // notificationBanerCount ==0 &&
          setNotificationData({
            show: true,
            type: 'FAILURE',
            title: 'Course File hosting is In-Progress',
            description: `${fileData?.name} is being hosted. Please wait for a few seconds before trying again.`,
          });
          setIsAPICallInProgress(true);
          setPreviewData(null);
          //  previewAdaptFileApiNew(versionNum);
          // setNotificationBanerCount( Number(notificationBanerCount)+1);
          // else {
          //   setPreviewData(apiData);
          // //  setOpenPreviewAdaptFile(true);
          //   setNotificationData({
          //     show: true,
          //     type: "FAILURE",
          //     title: "Course File hosting is In-Progress",
          //     description: `${fileData?.name} is being hosted. Currently you are pre-viewing the previously uploaded Course file. Please wait for a few seconds for pre-viewing the recently uploaded Course file.`,
          //   });
          //   // openInNewTab('url');
          // }
          setUploadingFileButton(false);
          setHeaderButtons(true);
        } else if (
          !apiData?.data?.isErrorOccuredInHosting &&
          apiData?.data?.version >= 1 &&
          apiData?.data?.isEarlierVersionAvailable
          // &&
          //apiData?.data?.version == versionNum
          //          apiData?.status?.toLowerCase() === "success" &&
          // !apiData?.data?.isErrorOccuredInHosting &&
          // apiData?.data?.version < versionNum
        ) {
          setPreviewData(apiData);
          // setNotificationData({
          //   show: true,
          //   type: 'FAILURE',
          //   title: 'Course File hosting is In-Progress',
          //   description: `${fileData?.name} is being hosted. Currently you are pre-viewing the previously uploaded Course file. Please wait for a few seconds for pre-viewing the recently uploaded Course file.`,
          // });
          setUploadingFileButton(false);
          setHeaderButtons(true);
          setOpenPreviewAdaptFile(true);
        } else if (
          (apiData?.data?.isErrorOccuredInHosting &&
            apiData?.data?.version == 1 &&
            versionNum == 1) ||
          (!apiData?.data?.isEarlierVersionAvailable &&
            apiData?.data?.version >= 1 &&
            apiData?.data?.version == versionNum)
        ) {
          setPreviewAdaptFileFlag(false);
          setIsAPICallInProgress(false);
          // setNotificationData({
          //   show: true,
          //   type: 'WARNING',
          //   title: 'File Hosting Failed',
          //   description:
          //     'Course file hosting failed. Please re-uplaod the course file to publish this course. Click on Course Information to access the upload button.',
          // });
          setUploadingFileButton(false);
          setHeaderButtons(true);
        } else if (
          apiData?.data?.isErrorOccuredInHosting &&
          apiData?.data?.isEarlierVersionAvailable &&
          apiData?.data?.version >= 1 &&
          apiData?.data?.version == versionNum
        ) {
          setPreviewData(apiData);
          // setNotificationData({
          //   show: true,
          //   type: 'WARNING',
          //   title: 'File Hosting Failed',
          //   description:
          //     'Course file hosting failed. Please re-upload the course file to publish this course. Click on Course Information to access the upload button. Currently you are pre-viewing previously uploaded Course file.',
          // });
          setUploadingFileButton(false);
          setHeaderButtons(true);
          setOpenPreviewAdaptFile(true);
        } else if (apiData?.data?.version < versionNum) {
          previewAdaptFileCreateNewCourse(locale, versionNum);
        }
      } else {
        setPreviewAdaptFileFlag(false);
        setIsAPICallInProgress(false);
        // setNotificationData({
        //   show: true,
        //   type: 'WARNING',
        //   title: 'File Hosting Failed',
        //   description:
        //     'Course file hosting failed. Please re-uplaod the Course file to publish this course. Click on Course Information to access the upload button.',
        // });
        setUploadingFileButton(false);
        setHeaderButtons(true);
      }
    }
  };

  const uploadNewImagePath = () => {
    router.push({
      pathname: '/add-new-image',
      //query: {actionName: ""},
    });
  };

  const uploadImageFromDesktop = (event, file) => {
    setImageFileDesk(file);
    setNewImageData(event);
    // const test = .readAsDataURL(data)
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      var base64_data = reader.result;

      //setMainImg(base64_data);
      setMainImageBackup(base64_data);
    };
    uploadNewImagePath();
  };

  useEffect(() => {
    if (newThumbnailId?.length > 0 && mainImageBackup?.length > 0) {
      let tempObj = JSON.parse(sessionStorage.getItem('path'));
      
      if (tempObj?.pathname === '/create-course-wizard' && editState == true) {
        setMainImageBackup(null);
      } else {
          setMainImageBackup(mainImageBackup);
      }
      handleDataFill('thumbnailId', newThumbnailId);
    }
  }, [mainImageBackup]);

  useEffect(() => {
    openPreviewAdaptFile && openInNewTab('url');
  }, [openPreviewAdaptFile]);

  useEffect(() => {
    if (!apiData && newCourseData) {
      setFileData(newCourseData?.adaptFile);
      setFileDataHindi(newCourseData?.adaptFileHindi);
      setCategoryData(newCourseData?.categoryData ? newCourseData?.categoryData : '');
      setPillsAddAudienceGroup(newCourseData?.pillsAddAudienceGroup);
      setNewCourseID(newCourseData?.newCourseID);
      setTypeCheckbox(newCourseData?.data?.types?.toString());
      setPillsDataKeyword(newCourseData?.keyword);
      setPillsDataSkills(newCourseData?.skills);
      setData((prevData) => ({
        ...prevData,
        ...newCourseData?.data,
        thumbnailId: newThumbnailId ? newThumbnailId : newCourseData?.data?.thumbnailId,
      }));
      validateCourseName(newCourseData?.data?.primaryTitle)
      setPublishButtonAfterPreview(newCourseData?.isPreviewAdaptFile);
      setAddUserGroupList(newCourseData?.addUserGroupList || []);
      setAddUserGroupListCopy(newCourseData?.addUserGroupListCopy || []);
      setDeleteUserGroupList(newCourseData?.deleteUserGroupList || []);
      newCourseData?.data?.courseState === 'Live' && setDisableStateUserManagement(true);
      newCourseData?.data?.courseState === 'Offline' && setDisableStateUserManagement(false);
    }
    if (
      newCourseData?.data?.primaryDescription?.length >= 3 &&
      newCourseData?.data?.primaryTitle?.length >= 3
    ) {
      setIsCourseIDFetched(true);
    }

    if (mainImageBackup === null && newCourseData?.thumbnail) {
      setMainImageBackup(newCourseData?.thumbnail);
    }
    if (newCourseWizardState?.courseWizardStateOpen === 'edit') {
      setEditState(true);
      setMainState(false);
      setNextState(false);
      setNextTranslation(false);
      setNextUserGrp(false);
      setPublishBtn(false);
    }
    newCourseData != null &&
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
  }, [newCourseData]);

  const publishNewCourse = async () => {
    const date = new Date();
    if (allCheckFunc() && publishBtn) {
      setNextButtonDisable(false);
      let publishData = data;
      publishData.date = date.toISOString();
      publishData.courseState = 'Live';
      await checkFun(publishData, true);
      setMainImageBackup(null);
    }
  };

  const changeCourseStatus = async (courseId, elementData, status) => {
    const apiData = await makeRequest({
      method: 'PATCH',
      endpoint: `/admin/api/v1/courses/${courseId}/${status}`,
    });
    if (apiData !== 'ERR_NETWORK') {
      status == 'archive' &&
        setNotificationData({
          show: true,
          type: 'SUCCESS',
          title: 'Course Archived',
          description: `${elementData.primaryTitle} has been archived by the ${oktaToken.idToken.claims.fullName}.`,
        });

      // await fetchCoursesList();
      fetchSelectedCourse(data.courseId);

      setNotificationListApi();
      setDashboardNotificationCountApi(currentUserId);
    }
    return closeCourseWizard() && setIsArchive(false);
  };

  const updateEditCourseDetails = async () => {
    let publishData = Object.assign({}, data);
    let tempCategories = '';
    let tempSkills = [];
    let tempKeywords = [];
    let addUserList = [];
    let deleteUserList = [];
    if (typeof data?.category === 'string') {
      tempCategories = data?.category;
    }
    if (typeof data?.category === 'object') {
      data?.category?.map((ele) => {
        tempCategories = ele?.id;
      });
    }
    data?.skills?.map((ele) => {
      ele.id ? tempSkills.push(ele.id) : tempSkills.push(ele);
    });
    data?.keywords?.map((ele) => {
      ele.id ? tempKeywords.push(ele.id) : tempKeywords.push(ele);
    });
    publishData.date = new Date().toISOString();
    publishData.courseState = 'Draft';

    if (publishData?.primaryTitle?.length > 1 && publishData?.chineseTitle?.length > 1) {
      publishData.isMultilingual = Boolean(true);
    } else {
      publishData.isMultilingual = Boolean(false);
    }
    if (publishData?.types?.length > 0) {
      publishData.types = publishData?.types;
    }
    // else {
    //   publishData.types = publishData?.types;
    // }

    delete publishData?.id;
    delete publishData?.courseUrl;
    delete publishData?.thumbnail;
    delete publishData?.updatedAt;
    delete publishData?.updatedBy;
    // delete publishData?.types;
    delete publishData?.course_state;
    delete publishData?.filename;
    delete publishData?.isMultilingual;
    delete publishData?.launchDate;
    delete publishData?.category;
    delete publishData?.skills;
    delete publishData?.keywords;
    delete publishData?.createdBy;

    publishData.category = tempCategories;
    publishData.skills = tempSkills;
    publishData.keywords = tempKeywords;
    publishData.version = data?.version;
    publishData.isDownloadAvailable = true;

    if (publishData?.thumbnailId === null) {
      delete publishData?.thumbnailId;
    }
    if (publishData?.adaptFileName === null) {
      delete publishData?.adaptFileName;
    }
    addUserGroupList?.forEach((ele) => {
      addUserList?.push({
        audGrpId: ele?.id,
        courseType: ele?.courseType,
      });
    });
    deleteUserGroupList?.forEach((ele) => {
      deleteUserList?.push({
        audGrpId: ele?.id,
        courseType: ele?.courseType,
      });
    });
    publishData.audienceGroups = {
      deleteAudGrpIds: deleteUserList,
      addAudGrpIds: addUserList,
    };
    publishData.adaptFileName = data?.adaptFileName;

    await updateCourseDetails(data?.id, publishData, false, false, null);
  };

  const createNewCourse = async (file, locale) => {
    let courseDetails = {};
    courseDetails = data;
    courseDetails.isDownloadAvailable = true;
    if (data?.audienceGroups?.addAudGrpIds?.length > 0) {
      courseDetails.audienceGroups = {
        deleteAudGrps: [],
        addAudGrps: data?.audienceGroups?.addAudGrpIds.map((element) => {
          return {
            ...element,
            audGrpName: element.audGrpId,
            countries: countryList.map((element) => element.countryCode),
          };
        }),
      };
    } else {
      delete courseDetails.audienceGroups;
    }
    const apiData = await makeRequest({
      method: 'POST',
      endpoint: '/admin/api/v1/course',
      body: JSON.stringify(courseDetails),
    });
    if (apiData) {
      let tempData = null;
      let newCourseIDData = null;
      newCourseIDData = apiData?.data;
      tempData = {...courseDetails, ...apiData?.data};
      setNewCourseID(apiData?.data);
      setData((prevData) => {
        return {...prevData, ...apiData?.data};
      });

      setIsCourseIDFetched(true);
      await fetchSelectedCourse(apiData?.data?.courseId);

      if (file) {
        await uploadToS3(file, locale, apiData?.data?.courseId, tempData, newCourseIDData);
      }
    }
  };

  useEffect(() => {
  if(createCourseNotification) {
    if(data?.adaptFileName?.length > 0) {
      const {primaryTitle} = data;
      setNotificationData({
        show: true,
        type: 'SUCCESS',
        title: 'New Course Added',
        description: `${primaryTitle} has been added to the Course Management by ${oktaToken.idToken.claims.fullName}`,
      });
    }
    setCreateCourseNotification(false);
  }
  }, [createCourseNotification]);

  const callCreateCourseAPI = async (file, locale) => {
    const {primaryTitle, primaryDescription} = data;
    if (data.courseId === null || data.courseId === undefined) {
      if (primaryTitle && primaryTitle?.length >= 1 && primaryDescription) {
        await createNewCourse(file, locale);
        setCreateCourseNotification(true);
      } else {
        primaryTitle &&
          primaryTitle?.length >= 0 &&
          primaryTitle?.length < 1 &&
          setNotificationData({
            show: true,
            type: 'FAILURE',
            title: 'Missing Mandatory Fields',
            description:
              'Title, Description and Thumbnail Image are mandatory to save the Course in Draft state and to move forward in the Create Course wizard.',
          });
      }
    }
  };
  // File Upload to S3 functionality -
  const syncToS3 = async (url, fileData, id, locale, tempData, newCourseIDData) => {
    const configData = JSON.parse(localStorage.getItem('configData'));

    await fetch(url, {
      method: 'PUT',
      body: fileData,
      headers: {
        'Content-Type': 'application/zip',
        'Content-Encoding': 'gzip',
      },
    })
      .then(async (res) => {
        if (res.statusText === 'OK') {
          let versionNum = Number(0);
          if (locale == configData?.locale) {
            versionNum = data.version ? Number(data.version) : Number(0);
          }
          // await fetchSelectedCourse(data.courseId);
          versionNum = versionNum + 1;

          setAdaptFileState('INPROGRESS');
          setOpenAdaptFileModal(true);
          if (editState) {
            await previewAdaptFileApi(versionNum, id, locale, fileData, tempData, newCourseIDData);
            //await publishEditCourseDetails(false, false, locale, fileData);
            // updateCourseDetails(data?.courseId,{adaptFileName:fileData?.name,courseState:data?.courseState
            //  ,version :versionNum
            // })
            //publishEditCourseDetails(false);
          } else if (mainState || nextState) {
            await previewAdaptFileApi(versionNum, id, locale, fileData, tempData, newCourseIDData);
            //await publishEditCourseDetails(false, false, locale, fileData);
            setPreviewAdaptFileFlag(true);
            //previewAdaptFileApiNew(versionNum);
          }
          setPublishButtonAfterPreview(true);
        } else {
          let versionNum = 0;
          if (locale == configData?.locale) {
            versionNum = data.version ? Number(data.version) : 0;
          }
          if (locale == configData?.locale) {
            setNotificationData({
              show: true,
              type: 'WARNING',
              title: `Course File ${versionNum == 0 ? 'Uploading' : 'Replacing'} Failed`,
              description: `${fileData?.name}  has not been uploaded. Please re-upload the Course file.`,
            });
          }
        }
        if (locale == configData?.locale) {
          setUploadingFileButton(false);
          setUploadingFileButtonHindi(false);
        }

        setSaveAndExitDisable(false);
        if (mainState && data?.adaptFileName?.length > 0) {
          setNextButtonDisable(false);
        } else if (allCheckFunc() && nextState) {
          setNextButtonDisable(false);
        } else {
          !allCheckFunc() && nextState && setNextButtonDisable(true);
        }
        setPublishButtonDisableEdit(false);
        //setBtn2SaveAndExitDisable(false);
        setDisableImageLib(false);
        setHeaderButtons(true);
      })
      .catch((error) => {
        setUploadingFileButton(false);
        setUploadingFileButtonHindi(false);
        setSaveAndExitDisable(false);
        setNextButtonDisable(false);
        setPublishButtonDisableEdit(false);
        setBtn2SaveAndExitDisable(false);
        setDisableImageLib(false);
        setHeaderButtons(true);
      });
  };

  const uploadToS3 = async (fileData, locale, id, tempData, newCourseIDData) => {
    const configData = JSON.parse(localStorage.getItem('configData'));
    setSaveAndExitDisable(true);
    setNextButtonDisable(true);
    setPublishButtonDisableEdit(true);
    setBtn2SaveAndExitDisable(true);
    setDisableImageLib(true);
    if (locale == configData?.locale) {
      setUploadingFileButton(true);
    }
    setHeaderButtons(false);
    const courseId = (newCourseID && newCourseID.courseId) || id;

    const apiData = await makeRequest({
      method: 'GET',
      endpoint: `/admin/api/v1/courses/${courseId}/upload?locale=${locale}`,
    });
    if (apiData) {
      let url = apiData?.data?.preSignedUrl;

      await syncToS3(url, fileData, courseId, locale, tempData, newCourseIDData);

      await fetchSelectedCourse(data.courseId);
    } else {
      if (locale == configData?.locale) {
        setUploadingFileButton(false);
        setUploadingFileButtonHindi(false);
      }
    }
  };

  const handleButton = async () => {
    !editState && !exitButtonEnable ? setIsLoading(true) : null;
    setBlockNavigation(false);
    if (nextState || mainState || nextTranslation || nextUserGrp) {
      if (
        (apiData?.course_state === 'Draft' ||
          newCourseData?.data?.course_state === 'Draft' ||
          apiData?.courseState === 'Draft' ||
          newCourseData?.data?.courseState === 'Draft') &&
        !openNewCourse &&
        !exitButtonEnable &&
        isCourseIDFetched &&
        (data?.id || data?.courseId)
      ) {
        await updateEditCourseDetails();
        setTimeout(() => {
          closeCourseWizard();
          setNewCourseWizardState({
            courseWizardOpen: false,
            courseWizardStateOpen: '',
            section: '',
          });
          setMainImageBackup(null);
          setImageData({
            imageName: null,
            imageDescription: null,
            pillsData: null,
          });
          setNewImageData(null);

          setCroppedImageData(null);
          setCroppedImage_16_9(null);
          setCroppedImage_1_1(null);
          setSelectedImage_16_9_Backup(null);
          setSelectedImage_1_1_Backup(null);
          setNewCourseData(null);
        }, 2000);
      } else {
        if (data?.adaptFileName?.length > 0 && data?.thumbnailId) {
          await saveAndExitClick();
        } else if (data?.adaptFileName?.length === 0 && adaptFileState === 'FAILURE') {
          setIsLoading(true);
          await deleteCourseIfUploadFailed(data?.courseId, data?.audienceGroups)
          setIsLoading(false);
        }
        // await saveAndExitClick();
        setTimeout(() => {
          closeCourseWizard();
          setNewCourseWizardState({
            courseWizardOpen: false,
            courseWizardStateOpen: '',
            section: '',
          });
          setMainImageBackup(null);
          setImageData({
            imageName: null,
            imageDescription: null,
            pillsData: null,
          });
          setNewImageData(null);
          setCroppedImageData(null);
          setCroppedImage_16_9(null);
          setCroppedImage_1_1(null);
          setSelectedImage_16_9_Backup(null);
          setSelectedImage_1_1_Backup(null);
          // fetchCoursesList();
        }, 2000);
      }
    } else if (editState) {
      setTimeout(() => {
        closeCourseWizard();
        setNewCourseWizardState({
          courseWizardOpen: false,
          courseWizardStateOpen: '',
          section: '',
        });
        setMainImageBackup(null);
      }, 2000);
      fetchSelectedCourse(data?.courseId);
    }
    setTimeout(() => {
      setImageData({
        imageName: null,
        imageDescription: null,
        pillsData: null,
      });
      setNewImageData(null);
      setMainImageBackup(null);
      setCroppedImageData(null);
      setCroppedImage_16_9(null);
      setCroppedImage_1_1(null);
      setSelectedImage_16_9_Backup(null);
      setSelectedImage_1_1_Backup(null);
      setIsLoading(false);
    }, 2000);
  };
  const handleNextButton = async () => {
    setIsLoading(true);
    let tempArr = [];
    let headerIndex = list.findIndex((data) => {
      return data.title == subheader;
    });
    if (headerIndex !== null && headerIndex !== -1) {
      list.forEach((data, index) => {
        if (index <= headerIndex + 1) {
          tempArr.push({
            className: data.className,
            title: data.title,
            subHeaderClick: true,
          });
        } else {
          tempArr.push(data);
        }
      });
    }
    setList(tempArr);
    setSubheader(list[headerIndex + 1]?.title);

    if (list[headerIndex + 1]?.title === 'Categories, Skills, Tags') {
      setPublishBtn(true);
      setNextButtonDisable(true);
      if (
        data?.category?.length > 0 &&
        data?.primaryTitle?.length > 0 &&
        data?.primaryDescription?.length > 0 &&
        data?.duration > 0 &&
        data?.adaptFileName?.length > 0 &&
        data?.thumbnailId?.length > 0
      ) {
        setNextButtonDisable(false);
      }
    }

    if (mainState) {
      setNextState(false);
      setNextTranslation(true);
      setNextUserGrp(false);
      setPublishBtn(false);
      setMainState(false);
      setList(tempArr);
      setNextButtonDisable(false);
      setHeaderButtons(true);
      setPreviewAdaptFileFlag(true);
      // fileData && previewAdaptFileApiNew();
      if (
        (allCheckFunc() &&
          (apiData?.course_state === 'Draft' ||
            newCourseData?.data?.course_state === 'Draft' ||
            apiData?.courseState === 'Draft' ||
            newCourseData?.data?.courseState === 'Draft')) ||
        data?.courseState?.toLowerCase() === 'draft'
      ) {
        setNextButtonDisable(false);
        //setPublishButtonDisableEdit(false);
      } else if (allCheckFunc()) {
        setNextButtonDisable(false);
      } else if (
        data?.category?.length > 0 &&
        data?.primaryTitle?.length > 0 &&
        data?.primaryDescription?.length > 0 &&
        data?.duration > 0 &&
        data?.adaptFileName?.length > 0 &&
        data?.thumbnailId?.length > 0
      ) {
        setNextButtonDisable(false);
      }
      // previewAdaptFileFlag &&
      //   setNotificationData({
      //     show: true,
      //     type: "FAILURE",
      //     title: "Course file hosting is In-Progress",
      //     description: `${fileData?.name} is being hosted. Please wait for a few seconds before trying again.`,
      //   });
      if (list[headerIndex + 1].title == 'Translation') {
        const url = '/create-course-wizard';
        let subheader = 'course_description';
        setNextTranslation(true);
        if (newCourseID?.courseId) {
          router.push({
            pathname: url,
            search: `?id=${
              newCourseID?.courseId ? newCourseID?.courseId : 0
            }&sub_header=${subheader}`,
            //query: {actionName: ""},
          });
        }
      }
      //  else if (list[headerIndex + 2].title == 'User Group Targeting') {

      // }
    } else if (nextTranslation) {
      setNextTranslation(false);
      setNextUserGrp(true);
      setSubheader(list[headerIndex + 1].title);
    } else if (nextUserGrp) {
      setNextUserGrp(false);
      setNextState(true);
      setSubheader(list[headerIndex + 1].title);
    } else if (nextState || publishBtn) {
      if (
        apiData?.course_state === 'Draft' ||
        newCourseData?.data?.course_state === 'Draft' ||
        apiData?.courseState === 'Draft' ||
        newCourseData?.data?.courseState === 'Draft' ||
        data?.courseState?.toLowerCase() === 'draft'
      ) {
        setBlockNavigation(false);
        await publishEditCourseDetails(
          true,
          true,
          '',
          null,
          false,
          data,
          addUserGroupList,
          deleteUserGroupList,
        );
        setNextState(false);
        setNextButtonDisable(false);
        setNextTranslation(false);
        // setNextUserGrp(true); // commenting this line to resolve ABOACA-12023
        setPublishBtn(false);
        setMainState(false);
      } else {
        await publishNewCourse();
      }
      setTimeout(() => {
        closeCourseWizard();
        setNextState(false);
        setMainState(false);
        setNextTranslation(false);
        setNextUserGrp(false);
        setNextTranslation(false);
        setNextUserGrp(false);
        setNextButtonDisable(true);
        setPublishBtn(false);
        setHeaderButtons(false);
        setNewCourseWizardState({
          courseWizardOpen: false,
          courseWizardStateOpen: '',
          section: '',
        });
        setImageData({
          imageName: null,
          imageDescription: null,
          pillsData: null,
        });
        setMainImageBackup(null);
        setNewImageData(null);

        setCroppedImageData(null);
        setCroppedImage_16_9(null);
        setCroppedImage_1_1(null);
        setSelectedImage_16_9_Backup(null);
        setSelectedImage_1_1_Backup(null);
      }, 2000);
    } else if (editState) {
      await publishEditCourseDetails(
        false,
        true,
        '',
        null,
        false,
        data,
        addUserGroupList,
        deleteUserGroupList,
      );

      setBlockNavigation(false);
      setTimeout(() => {
        closeCourseWizard();
        setNewCourseWizardState({
          courseWizardOpen: false,
          courseWizardStateOpen: '',
          section: '',
        });
        setImageData({
          imageName: null,
          imageDescription: null,
          pillsData: null,
        });
        setMainImageBackup(null);
        setNewImageData(null);

        setCroppedImageData(null);
        setCroppedImage_16_9(null);
        setCroppedImage_1_1(null);
        setSelectedImage_16_9_Backup(null);
        setSelectedImage_1_1_Backup(null);
      }, 2500);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };
  const subHeaderCategoriesClick = (val) => {
    if (allCheckFunc()) {
      setNextButtonDisable(false);
    } else if (
      data?.category?.length > 0 &&
      data?.primaryTitle?.length > 0 &&
      data?.primaryDescription?.length > 0 &&
      data?.duration > 0 &&
      data?.adaptFileName?.length > 0 &&
      data?.thumbnailId?.length > 0
    ) {
      setNextButtonDisable(false);
    } else {
      setNextButtonDisable(true);
    }
    setNextState(val);
    setMainState(false);
    //setEditState(false);
  };

  const subHeaderDescriptionClick = (val) => {
    if (data && data?.primaryTitle?.length > 0 && data?.primaryDescription?.length > 0) {
      setNextButtonDisable(false);
      setBlockNavigation(true);
    } else if (
      data?.category?.length > 0 &&
      data?.primaryTitle?.length > 0 &&
      data?.primaryDescription?.length > 0 &&
      data?.duration > 0 &&
      data?.adaptFileName?.length > 0 &&
      data?.thumbnailId?.length > 0
    ) {
      setNextButtonDisable(false);
    } else {
      setNextButtonDisable(true);
    }
    setNextState(false);
    setMainState(val);
    //setEditState(false);
  };

  const handleFileUpload = async (event, id, locale = '', version) => {
    const configData = JSON.parse(localStorage.getItem('configData'));
    let _size = event?.target?.files[0].size.toString();
    /**
     * File sizes in kB and MB Until the file size in bytes would be upto 7 digits
     * if the bytes would have 7 to 10 digits, we would have to divide it by 10**3(n) where n is the appending action
     */
    if (_size.length < 7) {
      _size = Math.round(_size / 1024).toFixed(); // will return size in KB
    }
    _size = (Math.round(_size / 1024) / 1000).toFixed(2); // will return size in MB

    if (
      event.target.files[0].name.includes('.zip') &&
      (event.target.files[0].type == 'application/zip' ||
        event.target.files[0].type == 'application/x-zip-compressed') &&
      _size <= 100
    ) {
      setSaveAndExitDisable(true);
      setNextButtonDisable(true);
      setPublishButtonDisableEdit(true);
      setBtn2SaveAndExitDisable(true);
      setDisableImageLib(true);
      if (locale == configData?.locale) {
        setUploadingFileButton(true);
      }
      setHeaderButtons(false);
      if (locale == configData?.locale) {
        setNotificationData({
          show: true,
          type: 'UPLOADING',
          title: `Course File ${data?.adaptFileName?.length > 0 ? 'replace' : 'upload'} has started`,
          description: `${event.target.files[0]?.name} upload in-progress. `,
        });
      }
      if (id != null && id != undefined) {
        await uploadToS3(event.target.files[0], locale, data?.courseId, data, newCourseID);
      } else {
        await callCreateCourseAPI(event.target.files[0], locale);
        await fetchSelectedCourse(data.courseId);
      }

      event.target.value = null;
    } else {
      setNotificationData({
        show: true,
        type: 'WARNING',
        title: 'Invalid File',
        // description: `Please upload .zip, .rar or .7zip files only!`,
        description: 'Please upload only .zip files of size 100MB max!',
      });
    }
  };

  const updateUserGroups = () => {
    let addUserList = [];
    let deleteUserList = [];
    addUserList = audienceListForEachFun(addUserGroupList);
    deleteUserList = audienceListForEachFun(deleteUserGroupList);

    setData((prevData) => ({
      ...prevData,
      audienceGroups: {
        deleteAudGrpIds: deleteUserList,
        addAudGrpIds: addUserList,
      },
    }));
  };

  const nextDisableForUGM = () => {
    if (subheader === 'User Group Targeting') {
      let nxtDisable = countryList.some((item) => item.checked);
      return !nxtDisable;
    }
    return false;
  };

  useEffect(() => {
    isAPICallInProgress &&
      setNotificationData({
        show: true,
        type: 'FAILURE',
        title: 'Course file hosting is In-Progress',
        description: `${fileData?.name} is being hosted. Please wait for a few seconds before trying again.`,
      });
  }, [isAPICallInProgress]);

  useEffect(() => {
    mainState && !apiData?.courseId && !newCourseData && handleUserTargetingGroupsList();
    apiData?.courseId && !openNewCourse && handleUserTargetingGroupsListDelete();
  }, [audienceGroup, apiData]);

  useEffect(() => {
    updateUserGroups();
  }, [addUserGroupList, deleteUserGroupList, countryList]);

  const handleUserTargetingGroupsListDelete = () => {
    let updatedCountryList = countryList.map((country) => {
      return {
        ...country,
        checked:
          Array.isArray(apiData?.countryCodes) && apiData?.countryCodes?.length > 0
            ? apiData?.countryCodes?.includes(country.countryCode)
            : true,
      };
    });
    setCountryList(updatedCountryList);
    let tempArr = [];
    let resultsDelete = [];

    if (audienceGroup?.length !== apiData?.audienceGroups?.length) {
      const results = audienceGroup?.filter(
        ({title: id1}) => !apiData?.audienceGroups?.some(({title: id2}) => id2 === id1),
      );
      const ids = results.map((o) => o.id);
      resultsDelete = results.filter(({id}, index) => {
        return !ids.includes(id, index + 1);
      });
      //resultsDelete = results?.filter(({ id: id1 }) => !deleteUserGroupList.some(({ id: id2 }) => id2 === id1));
      resultsDelete?.forEach((data) => {
        tempArr.push({
          id: data.title,
          title: data.title,
          courseType: 'General visibility',
          audienceGrpDescription: data?.audienceGrpDescription || data?.description,
        });
      });
    }
    setDeleteUserGroupList(tempArr);
  };

  const handleUserTargetingGroupsList = () => {
    let obj = {};
    let tempArr = [];
    audienceGroup?.forEach((data) => {
      obj = {
        title: data.title,
        audGrpId: data.title,
        audienceGrpDescription: data.audienceGrpDescription || data?.description,
        courseType: 'General visibility',
        countries: countryList.map((ele) => ele.countryCode),
        //add all the country codes to be sent
      };
      tempArr.push(obj);
    });

    setAddUserGroupList(tempArr);
  };

  const handleDeleteUserTargetingGroups = (obj, list, element) => {
    let tempArr = [];
    tempArr = list;
    const index = list?.findIndex((ele) => {
      return ele.id == obj.id || ele.audGrpId == obj.id;
    });
    //Finding the index of the element in addUsrGrp list and changing the courseType for that
    if (index !== undefined && index !== -1) {
      tempArr.splice(index, 1, obj);
    }
    setAddUserGroupList(tempArr);
    updateUserGroups();
    editState || newThumbnailId?.length > 0 ? editSaveAndExitEnable(data) : null;
  };
  const handleAddUserTargetingGroups = (obj, list) => {
    const updatedList = deletedAudGrpPayloadList?.filter((item) => item?.title !== obj?.title);
    setDeletedAudGrpPayloadList(updatedList);
    let tempArr = [];
    let tempArr2 = deleteUserGroupList;
    tempArr = [...addUserGroupList, obj];
    setAddUserGroupList(tempArr);
    const index = deleteUserGroupList?.findIndex((ele) => {
      return ele.id == obj.id || ele.audGrpId == obj.id;
    });
    if (index !== undefined && index !== -1) {
      tempArr2.splice(index, 1);
    }
    setDeleteUserGroupList(tempArr2);
    updateUserGroups();
    editState || newThumbnailId?.length > 0 ? editSaveAndExitEnable(data) : null;
  };
  const handleDeleteUserGroups = (obj, list) => {
    let newDeleteAudGrpList = apiData?.audienceGroups?.some((item) => item.title === obj.title);

    if (newDeleteAudGrpList) {
      setDeletedAudGrpPayloadList((prev) => [...prev, obj]);
    }
    let tempArr = [];
    let tempArr2 = addUserGroupList;
    const results = deleteUserGroupList?.find((data) => {
      return data.id == obj?.id || data.audGrpId == obj.id;
    });
    const val = addUserGroupListCopy?.find((data) => {
      return data.id == obj?.id || data.audGrpId == obj.id;
    });
    if (results == undefined) {
      if (val !== undefined) {
        tempArr = [...deleteUserGroupList, val];
      } else {
        tempArr = [...deleteUserGroupList, obj];
      }
    }
    const index = addUserGroupList?.findIndex((ele) => {
      return ele.id == obj.id || ele.audGrpId == obj.id;
    });
    if (index !== undefined && index !== -1) {
      tempArr2.splice(index, 1);
    }
    setDeleteUserGroupList(tempArr);
    setAddUserGroupList(tempArr2);
    updateUserGroups();
    editState || newThumbnailId?.length > 0 ? editSaveAndExitEnable(data) : null;
  };

  const disableClickBanner = () => {
    data.courseState === 'Live' &&
      setNotificationData({
        show: true,
        type: 'FAILURE',
        title: 'Editing Course.',
        description: `Editing is limited to Course Title, Description, Categories, Skills, Tags, and Thumbnails when a course is Live. To make edits to additional sections of this course, change the course to offline mode in the Course Details.`,
      });
  };

  const selectedSubHeaderNew = (title) => {
    if (title == 'Categories, Skills, Tags') {
      subHeaderCategoriesClick(true);
      setSubheader(title);
      setMainState(false);
      setNextState(true);
      setNextTranslation(false);
      setNextUserGrp(false);
      setPublishBtn(true);
      setEditState(false);
    } else if (title == 'Course Information') {
      subHeaderDescriptionClick(true);
      setSubheader(title);
      setMainState(true);
      setNextState(false);
      setNextTranslation(false);
      setNextUserGrp(false);
    } else if (title == 'Translation') {
      subHeaderDescriptionClick(true);
      setSubheader(title);
      setMainState(false);
      setNextState(false);
      setNextTranslation(true);
      setNextUserGrp(false);
    } else if (title == 'User Group Targeting') {
      subHeaderDescriptionClick(true);
      setSubheader(title);
      setMainState(false);
      setNextState(false);
      setNextTranslation(false);
      setNextUserGrp(true);
    }
    if (
      data?.category?.length > 0 &&
      data?.primaryTitle?.length > 0 &&
      data?.primaryDescription?.length > 0 &&
      data?.duration > 0 &&
      data?.adaptFileName?.length > 0 &&
      data?.thumbnailId?.length > 0
    ) {
      setPublishBtn(true);
      setNextButtonDisable(false);
      setPublishButtonDisableEdit(false);
      !editState && setExitButtonEnable(false);
    }
    setSubheader(title);
  };

  return (
    <React.Fragment>
      {/* Main Container start */}
      <div className={styles.modal_wrapper}>
        <div
          className={[styles.course_wizard_container, ''].join(' ')}
          ref={containerRef}
          id={'course_wrapper'}
        >
          {/* Header Component */}
          <Header
            isLoading={isLoading}
            headerName={
              !editState
              ? 'Create New Course Wizard'
              : apiData
              ? apiData?.primaryTitle
              : editState && newCourseData?.data?.primaryTitle
              ? newCourseData?.data?.primaryTitle
              : ''
            }
            headerXSName=" "
            btnName1={editState ? 'Cancel' : exitButtonEnable ? 'Exit' : 'Save & Exit'}
            btnName2={
              mainState || nextTranslation || nextUserGrp
                ? 'Next'
                : editState
                ? 'Save & Exit'
                : nextState
                ? 'Publish'
                : 'Publish'
            }
            isHeaderXS={false}
            isBtn1={true}
            isBtn2={true}
            isPreviewAdaptFile={true}
            headerButtons={headerButtons}
            isProgressBar={!editState}
            header_btn_adapt_option={nextState ? true : false}
            isBtn2Disable={
              editState ? btn2SaveAndExitDisable || isDuplicateCourseName : nextDisableForUGM() ? true : nextButtonDisable || isDuplicateCourseName
            }
            handleDisableClick={() => {
              mainState && fileData?.filename;
              if (isEdited) {
                setNotificationData({
                  show: true,
                  type: 'FAILURE',
                  title: 'Missing Mandatory Fields',
                  description:
                    'Title, Description and Thumbnail Image, Country Selection are mandatory to save the Course in Draft state and to move forward in the Create Course wizard.',
                });
              }

              if (uploadingFileButton) {
                setNotificationData({
                  show: true,
                  type: 'FAILURE',
                  title: 'Action restricted during File Uploading.',
                  description:
                    'Please wait for the file to upload to move forward in the Create Course wizard.',
                });
              }
            }}
            isBtn1Disable={nextDisableForUGM() ? true : saveAndExitDisable}
            handleButton={() => {
              setThumbnailImage169(null);
              handleButton();
            }}
            handleNextButton={() => {
              setThumbnailImage169(null);
              handleNextButton();
            }}
            subHeaderCategoriesClick={(val) => {
              !editState && subHeaderCategoriesClick(val);
            }}
            showSubHeader={true}
            list={list}
            selectedSubHeaderNew={(name) => {
              !editState && selectedSubHeaderNew(name);
            }}
            subheader={subheader}
            isSideNavigationPresent={false}
            setNextButtonDisable={setNextButtonDisable}
          />
          {/* Header Component */}

          <div className={[styles.course_wrapper, 'bootstrap-wrapper'].join(' ')}>
            {(mainState || nextState || nextTranslation || nextUserGrp) && (
              <CreateWizard
                adaptFileButtonState={adaptFileButtonState}
                mainState={mainState}
                nextState={nextState}
                nextTranslation={nextTranslation}
                nextUserGrp={nextUserGrp}
                openAdaptFileModal={openAdaptFileModal}
                adaptFileState={adaptFileState}
                fileDataTemp={fileDataTemp}
                fileDataHindi={fileDataHindi}
                fileDataTempHindi={fileDataTempHindi}
                data={data}
                setOpenAdaptFileModal={setOpenAdaptFileModal}
                openInNewTab={openInNewTab}
                setFileData={setFileData}
                regexNameChinese={regexNameChinese}
                regexNameHindi={regexNameHindi}
                handleDataFill={handleDataFill}
                handleDataFillMultiLingual={handleDataFillMultiLingual}
                addUserGroupList={addUserGroupList}
                deleteUserGroupList={deleteUserGroupList}
                disableStateUserManagement={disableStateUserManagement}
                handleDeleteUserTargetingGroups={handleDeleteUserTargetingGroups}
                handleDeleteUserGroups={handleDeleteUserGroups}
                disableClickBanner={disableClickBanner}
                handleFileUpload={handleFileUpload}
                uploadingFileButton={uploadingFileButton}
                uploadingFileButtonHindi={uploadingFileButtonHindi}
                adaptFileButtonStateHindi={adaptFileButtonStateHindi}
                fileData={fileData}
                getEnableSaveAndExit={getEnableSaveAndExit}
                isCourseIDFetched={isCourseIDFetched}
                disableImageLib={disableImageLib}
                mainImageBackup={mainImageBackup}
                setImageData={setImageData}
                setThumbnailImage169={setThumbnailImage169}
                setCourseMode={setCourseMode}
                setNewCourseData={setNewCourseData}
                setNewCourseWizardState={setNewCourseWizardState}
                uploadImageFromDesktop={uploadImageFromDesktop}
                uploadNewImagePath={uploadNewImagePath}
                setNewThumbnailImageData={setNewThumbnailImageData}
                thumbnailImage169={thumbnailImage169}
                setNewImageData={setNewImageData}
                setMainImageBackup={setMainImageBackup}
                setCroppedImageData={setCroppedImageData}
                setCroppedImage_16_9={setCroppedImage_16_9}
                setCroppedImage_1_1={setCroppedImage_1_1}
                setSelectedImage_16_9_Backup={setSelectedImage_16_9_Backup}
                setSelectedImage_1_1_Backup={setSelectedImage_1_1_Backup}
                previewAdaptFileCreateNewCourse={previewAdaptFileCreateNewCourse}
                setPreviewAdaptFileFlag={setPreviewAdaptFileFlag}
                previewData={previewData}
                categories={categories}
                categoryData={categoryData}
                setCategoryData={setCategoryData}
                skills={skills}
                skillsRef={skillsRef}
                setPillsDataSkills={setPillsDataSkills}
                pillsDataSkills={pillsDataSkills}
                pillsAddAudienceGroup={pillsAddAudienceGroup}
                pillsDataKeyword={pillsDataKeyword}
                filterListById={filterListById}
                handleAddUserTargetingGroups={handleAddUserTargetingGroups}
                setNotificationData={setNotificationData}
                newCourseID={newCourseID}
                addUserGroupListCopy={addUserGroupListCopy}
                publishButtonAfterPreview={publishButtonAfterPreview}
                keyword={keyword}
                keywordsRef={keywordsRef}
                setPillsDataKeyword={setPillsDataKeyword}
                SearchListComponent={SearchListComponent}
                closeCourseWizard={closeCourseWizard}
                isLoading={isLoading}
                remoteConfig={remoteConfig}
                replaceFile={replaceFile}
                isPrimaryCourseAdded={isPrimaryCourseAdded}
                setPrimaryCourseAdded={setPrimaryCourseAdded}
                showPrimaryNotification={showPrimaryNotification}
                isDuplicateCourseName={isDuplicateCourseName}
                missingFilesMessage={missingFilesMessage}
              />
            )}

            {/* Edit Course screen 3 Started */}
            {editState && (
              <EditWizard
                handleCountrySelection={handleCountrySelection}
                openAdaptFileModal={openAdaptFileModal}
                adaptFileState={adaptFileState}
                fileDataTemp={fileDataTemp}
                fileDataTempHindi={fileDataTempHindi}
                fileDataHindi={fileDataHindi}
                data={data}
                setOpenAdaptFileModal={setOpenAdaptFileModal}
                openInNewTab={openInNewTab}
                setFileData={setFileData}
                regexNameChinese={regexNameChinese}
                regexNameHindi={regexNameHindi}
                handleDataFill={handleDataFill}
                handleDataFillMultiLingual={handleDataFillMultiLingual}
                addUserGroupList={addUserGroupList}
                deleteUserGroupList={deleteUserGroupList}
                disableStateUserManagement={disableStateUserManagement}
                handleDeleteUserTargetingGroups={handleDeleteUserTargetingGroups}
                handleDeleteUserGroups={handleDeleteUserGroups}
                disableClickBanner={disableClickBanner}
                handleFileUpload={handleFileUpload}
                uploadingFileButton={uploadingFileButton}
                uploadingFileButtonHindi={uploadingFileButtonHindi}
                adaptFileButtonStateHindi={adaptFileButtonStateHindi}
                fileData={fileData}
                isCourseIDFetched={isCourseIDFetched}
                mainImageBackup={mainImageBackup}
                setImageData={setImageData}
                setThumbnailImage169={setThumbnailImage169}
                setCourseMode={setCourseMode}
                setNewCourseData={setNewCourseData}
                setNewCourseWizardState={setNewCourseWizardState}
                uploadImageFromDesktop={uploadImageFromDesktop}
                uploadNewImagePath={uploadNewImagePath}
                setNewThumbnailImageData={setNewThumbnailImageData}
                thumbnailImage169={thumbnailImage169}
                previewAdaptFileCreateNewCourse={previewAdaptFileCreateNewCourse}
                previewData={previewData}
                categories={categories}
                categoryData={categoryData}
                setCategoryData={setCategoryData}
                skills={skills}
                skillsRef={skillsRef}
                setPillsDataSkills={setPillsDataSkills}
                pillsDataSkills={pillsDataSkills}
                pillsAddAudienceGroup={pillsAddAudienceGroup}
                pillsDataKeyword={pillsDataKeyword}
                filterListById={filterListById}
                handleAddUserTargetingGroups={handleAddUserTargetingGroups}
                setNotificationData={setNotificationData}
                newCourseID={newCourseID}
                addUserGroupListCopy={addUserGroupListCopy}
                publishButtonAfterPreview={publishButtonAfterPreview}
                keyword={keyword}
                keywordsRef={keywordsRef}
                setPillsDataKeyword={setPillsDataKeyword}
                SearchListComponent={SearchListComponent}
                fetchSelectedCourse={fetchSelectedCourse}
                setOpenDecommission={setOpenDecommission}
                openDecommission={openDecommission}
                setIsArchive={setIsArchive}
                isArchive={isArchive}
                changeCourseStatus={changeCourseStatus}
                closeCourseWizard={closeCourseWizard}
                isLoading={isLoading}
                remoteConfig={remoteConfig}
                replaceFile={replaceFile}
                isDuplicateCourseName={isDuplicateCourseName}
                missingFilesMessage={missingFilesMessage}
              />
            )}
            {/* Edit Course screen 3 Ended */}
          </div>
        </div>
        {notificationData?.show && <Notification />}
      </div>
    </React.Fragment>
  );
};

CourseWizardComponent.props = {};
const mapStateToProps = (state, ownProps) => ({
  allState: state,
  allProps: ownProps,
  notificationData: state && state.uiState && state.uiState.notificationData,
  newCourseData: state && state.courseState && state.courseState.newCourseData,
  newImageData: state && state.courseState && state.courseState.newImageData,
  newThumbnailImage: state && state.courseState && state.courseState.newThumbnailImage,
  mainImageBackup: state && state.croppedImage && state.croppedImage.mainImageBackup,
  newAdaptFile: state && state.courseState && state.courseState.newAdaptFile,
  newCourseWizardState: state && state.courseState && state.courseState.newCourseWizardState,
  newThumbnailId: state && state.courseState && state.courseState.newThumbnailId,
  currentUserId: state && state.uiState && state.uiState.currentUserId,
  thumbnailImage169: state && state.croppedImage && state.croppedImage.thumbnailImage169,
  addedTranslationListState: state && state.uiState && state.uiState.addedTranslationListState,
  countryList: state && state.uiState && state.uiState.countryList,
  remoteConfig: state && state.uiState && state.uiState.remoteConfig,
  allCoursesList: state?.courseManagement?.allCoursesList || []
});

const mapDispatchToProps = (dispatch) => ({
  setNotificationData: (options) => dispatch(setNotificationData(options)),
  setMainImageBackup: (options) => dispatch(setMainImageBackup(options)),
  setNewCourseData: (options) => dispatch(setNewCourseData(options)),
  setNewImageData: (options) => dispatch(setNewImageData(options)),
  setNewCourseWizardState: (options) => dispatch(setNewCourseWizardState(options)),
  setNewAdaptFile: (options) => dispatch(setNewAdaptFile(options)),
  setCourseMode: (options) => dispatch(setCourseMode(options)),
  setBlockNavigation: (options) => dispatch(setBlockNavigation(options)),
  setNotificationListApi: (options) => dispatch(setNotificationListApi(options)),
  setDashboardNotificationCountApi: (options) =>
    dispatch(setDashboardNotificationCountApi(options)),
  setSelectedImage_16_9_Backup: (options) => dispatch(setSelectedImage_16_9_Backup(options)),
  setSelectedImage_1_1_Backup: (options) => dispatch(setSelectedImage_1_1_Backup(options)),
  setCroppedImage_16_9: (options) => dispatch(setCroppedImage_16_9(options)),
  setCroppedImage_1_1: (options) => dispatch(setCroppedImage_1_1(options)),
  setCroppedImageData: (options) => dispatch(setCroppedImageData(options)),
  setImageData: (options) => dispatch(setImageData(options)),
  setNewThumbnailImageData: (options) => dispatch(setNewThumbnailImageData(options)),
  setNewThumbnailId: (options) => dispatch(setNewThumbnailId(options)),
  setThumbnailImage169: (options) => dispatch(setThumbnailImage169(options)),
  setErrorStateTemplate: (options) => dispatch(setErrorStateTemplate(options)),
  setSelectedNavigationOption: (options) => dispatch(setSelectedNavigationOption(options)),
  setCountryList: (options) => dispatch(setCountryList(options)),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(CourseWizardComponent));
